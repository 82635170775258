/* eslint-disable */
import React from "react"
import { useNavigate } from "react-router-dom"

export const CallToActionButton = ({ titulo, url }) => {

    const navigate = useNavigate()

    const handlerGoTo = () => navigate(url) 
    return ( 
    <button 
        className="bg-[green] text-white font-[500] py-2 px-2 flex justify-center items-center text-center text-2xl"
        onClick={handlerGoTo}
        > 
        <span className="w-full animate-pulse"> 
            {titulo}
        </span>
    </button>
    )
}

export default CallToActionButton