/* eslint-disable */
/* global alert */
/* global localStorage */
import React, { useCallback, useEffect, useRef, useState, version } from "react";
import api from "../../services/api";
import {Header,Footer,Youtube,Carton,Tablero,Chat} from '..';
import 'react-tabs/style/react-tabs.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-tooltip/dist/react-tooltip.css'





function BolitasSet() {

    const [BolasSorteo,setBolasSorteo] = useState([]);
    const [BolasList ,setBolasList] = useState([]);
    const [BallDelay,setBallDelay] = useState(0);
    

    

 
 
    const UpdateBolasSorteo = async() =>{
        await api.get('/read/sorteo_bolitas')
        .then((response) =>{ 
            if(response?.data?.content.length > 0){
                const TempData = response?.data?.content                
                const BolasParse = TempData[0]?.bolas?.split(",") 
                if(BolasList.length === 0){
                    setBolasList(BolasParse)
                } 
                setBolasSorteo(BolasParse);
                //console.log('UpdateBolasSorteo',BolasParse)
            }
        })
        .catch((err) => console.error( err));
    }


    const GetBolasSorteo = async() =>{ 
        if(BolasList.length > 0) { 
            console.log('si hay bolitas',BolasList)
            let actuales = BolasList
            let cola = true  
            BolasSorteo.map( ( bolita, index ) => {
                if( bolita != actuales[index] ){
                    if(cola){ 
                        actuales.push(bolita)
                        cola = false
                    }
                }
                
            } ) 
            if(BolasSorteo.length == 1){
                setBolasList(BolasSorteo) 
            } else {
                setBolasList(actuales) 
            }
            
        }
    }  
      
    const Salieron = () => {
        return   BolasList
          .slice(-10)
          .reverse()
          .map((e, i) => (
            <div
              className={`bolita ${i === 0 ? 'ultima' : ''}`}
              data-number={e}
              key={`salieron-tabla-${i}`}>
              {e}
            </div>
          )) 
      };

    useEffect( () => {    
    
        const interval = setInterval(()=>{
            UpdateBolasSorteo()
        }, 5000);
  
        return () => {
            clearInterval(interval)
        };

    }, []);

    useEffect(() => { 
        const timeout = setTimeout(()=>{
            GetBolasSorteo()
        }, BallDelay);
        return  clearTimeout(timeout)
    }, [BolasSorteo]);

    useEffect( () => {    
        GetBolasSorteo()
    }, [BolasSorteo]);

console.log(BolasList, 'bloitas');

  
  


    return (
        <div className='flex overflow-x-hidden relative'> 
           
                        
                        <Salieron />
                         
                  
        </div>
      
    );
  }
  export default BolitasSet; 