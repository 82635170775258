/* eslint-disable */
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import '../../App.css';
function Loader() {
    
  return ( 
    <div className="loader-new">
      <img 
        src={'./logo_bingove_01.webp'} 
        alt=""  
        height={50} 
        width={"auto"}
        /> 
    </div>
    );
}
  
export default Loader;