/* eslint-disable */
import React from 'react'
import { Header } from '../../components' 
import HorizontalMenu from '../../components/HorizontalMenu/HorizontalMenu' 

function Dashboard({children}) {  
  return ( 
    <div> 
      <div className=' w-full fixed top-0 h-14 z-20 '>
        <header>
          <Header />
        </header>
      </div> 
      <main className='flex w-full transition-all'>  
        <div className='m-4 w-full items-center mx-auto justify-center'>
          {children}
        </div> 
      </main> 
      <footer className='bottom-0  overflow-y-hidden z-[10000]'>
        <HorizontalMenu />
      </footer>
    </div>
  )
}

export default Dashboard
