/* eslint-disable */
import { useState, useEffect, useRef, useContext } from 'react'
import api from '../../services/api'
import CartonCart from '../../components/carton/CartonCart'
import Cart from '../../components/Cart/Cart'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartShopping, faX, faFile } from '@fortawesome/free-solid-svg-icons'
import AutoPago from '../../components/AutoPago/AutoPago'
import AuthContext from '../../context/AuthContext'
import axios from 'axios'
import { renderHook } from '@testing-library/react'
import SimpleCard from '../../components/carton/SimpleCard'
import CompleteCard from '../../components/carton/CompleteCard'
import GetCarton from '../../components/GetCarton/GetCarton'
import { Dashboard } from '..'
import { GetAllConfigs } from '../../helpers/helpers'

const baseURL = process.env.REACT_APP_URL_API_V2

function CardList() {

  const context = useContext(AuthContext)
  const [data, setData] = useState({}) 
  const [configs, setConfigs] = useState([]);
  const [cardList, setCardList] = useState([]) 
  const [filteredCardList, setFilteredCardList] = useState([]) 
  const [serialToAdd, setSerialToAdd] = useState();
  const [Orden, setOrden] = useState("asc")
  const [listMode, setListMode] = useState(true);
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [page, setPage] = useState(1)
  const [isOpenOneCardModal, setIsOpenOneCardModal] = useState(false)
  const [selectedCard, setSelectedCard]= useState('')
  const [filterText, setFilterText]= useState('')
  
  const containerRef = useRef(null)
  const filterTextRef = useRef(null)
 
  //todo para  configuraciones 
  const [sorteoActual, setSorteoActual] = useState(''); 
  const [ventasStatus, setVentasStatus] = useState(false);

  const [textLoading, setTextLoading] = useState('')
  const [modalCart, setModalCart] = useState(false)
  const [modalPagar, setModalPagar] = useState(false)
  const [cart, setCart] = useState([]);
  const [renderCart, setRenderCart] = useState([])
 
  const [popUpTitle, setPopUpTitle] = useState('')
  const [popUpText, setPopUpText] = useState('')  

  const [order , setOrder] = useState(`#${Date.now()}`.replace(/#\d{4}/, "#"));

  
   

  const handleFilterText =  (value)=>{
     
      const Sanitize = value.replace(/[^0-9]/g, '');
      setFilterText(Sanitize)
    
  }
  const handleModalCart = () => {
    setModalCart(true)
  } 
  const GetGeneralConfigs = async () =>{
      const allConfigs = await GetAllConfigs()
      setConfigs( allConfigs ) 
  } 
  const GetSorteoActual = async () => {
    setIsLoading(true);
    const response = await api.get(`/read/sorteo_actual`); 
    if (response?.data?.content?.length > 0) {
      const sorteoTempo = response.data.content[0].valor
      setSorteoActual(sorteoTempo)
    }
    setIsLoading(false);
  }
  const GetCartones = async (currentPage) => {
    if( sorteoActual == '') return
    setIsLoading(true);

    try {
      const response = await api.get(`/onlyFree/${context.user.clientId}`); 

      if (response?.data?.content?.length > 0) {
        const TempCartones = response.data.content
        
        //console.log('filtrados:', TempCartones.sort((a, b) => a.id - b.id))

        setCardList(TempCartones.sort((a, b) => a.id - b.id)); 
        setIsLoading(false);
      }  
    } catch (err) {
      console.error('Error al cargar los cardList:', err);
      setIsLoading(false);
    }
    
  }  
  const handleScroll = () => {
    if (!containerRef.current || isLoading || !hasMore) return;

    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollHeight - scrollTop <= clientHeight + 50) {
      setPage((prevPage) => prevPage + 1); // Incrementa la página para cargar más
    }
  };  
  const desbloquearCarton = async(item, todos = false) => { 
    //api para desbloquear el carton 
    
    const requestData = new FormData(); 
    let url =  baseURL + "/desbloquearCarton" 

    if(todos){
      url =  baseURL + "/limpiarCarrito"  
      requestData.append('clienteId', context.user.clientId ) 
    } else { 
      requestData.append('serial', `${item?.serial}` );
      requestData.append('clienteId', context.user.clientId ) 
    }
    await axios({
      method: "post",
      url: url,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) =>{
        //handle success
        const responseStatus = response?.data?.status;
        const responseMessage = response?.data?.status_message; 
        if(responseStatus != '1'){  
          if(responseMessage){ 
            //console.log('responseMessage',responseMessage) 
          } else {
             console.log('Error de API E202404',response) 
          }
        }
         
      })
      .catch((error) =>{ 
        console.log('error al desbbloquear',error )
      });
     
  }
  const checkCarton = async(item) => {
    let responseCheck = ''
    //api para bloquear el carton 
    const requestData = new FormData();
    requestData.append('serial', `${item?.serial}` );
    requestData.append('clienteId', context.user.clientId )
    //ya no es necesario chequear el limite de separacion 
    
    const exist = cart.filter((carton)=>carton.serial == item?.serial)
    let url = baseURL+ "/bloquearCarton"

    if(exist.length > 0){
      url = baseURL + "/desbloquearCarton"
    }  
    await axios({
      method: "post",
      url: url,
      data: requestData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) =>{
        //handle success
        const responseStatus = response?.data?.status;
        const responseMessage = response?.data?.status_message;
        const responseContent = response?.data?.content;
        if(responseStatus == '1'){
          responseCheck = 'true'
          if(responseMessage){ 
            //console.log('Respuesta bloquear carton cuando es 1: ', responseMessage)
            //actualizar cartonessetCartones
            setCart((prev)=> prev.map((carton)=>{
                if(carton.serial == item.serial){
                  return {...carton, id_cliente: context.user.clientId}
                } else {
                  return carton
                }
            }) )
          }  
        } else {
          if(responseMessage){ 
            responseCheck = responseMessage
            //console.log('Respuesta bloquear carton cuando es 0: ', responseMessage)
            GetCartones()
          } else {
            responseCheck = 'Error de API E200404'
            console.log('response',response)
          }
        }
        
      })
      .catch((error) =>{
        //handle error
        responseCheck = error
        console.log(error, "ops, hubo un error");
      });
    return responseCheck
  }
 

  const removeFromCart = async (item, all = false) => {
    // activamos el loader
    setIsLoading(true)
    //debemos primero verificar que el carton esta libre
    // carton este libre
    //console.log('check',item)
    await desbloquearCarton(item, all)
    // desbloqueamos
    //  
    GetCartones()  
    setIsLoading(false) 
    
  }
  const addToCart = async (item) => {
    // activamos el loader
    setIsLoading(true)
    //debemos primero verificar que el carton esta libre
    // carton este libre
    const cartonBloqueado = await checkCarton(item)
    // bloqueamos
    // 
    if(cartonBloqueado == 'true'){ 
      //console.log("%c el carton ha sido bloqueado",'color:green')
      GetCartones()  
      setIsLoading(false)

    } else {
      //forzar la actualizacion de cardList
      //mostrar mensajes  cartonBloqueado
      showPopUp(cartonBloqueado)
      console.log("%c ERROR al bloquear el carton",'color:red')
      setIsLoading(false)
      //GetCartones() 
    }
  }
 
  const showPopUp = (text = '', title = 'Error') =>{
    setPopUpTitle(title)
    setPopUpText(text)
  }


  const CartonLoader = () => {
    return (
      <div className="flex bg-[#0007] w-[100vw] h-[100vh] top-0 left-0 right-0 justify-center items-center fixed  z-[100]">
        <div className="flex justify-center loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32">
        </div>
        <span className='text-center text-white fixed font-bold'>
          {textLoading != '' ? textLoading : 'CARGANDO'}
        </span>
      </div>
    )
  }

  const PopUpDialog = ({}) => {
    return (
      <div className="flex bg-[#0007] w-[100vw] h-[100vh] top-0 left-0 right-0 justify-center items-center fixed  z-[100]">
        <div className="flex gap-2 flex-col justify-between items-center bg-white rounded h-52 w-52 p-3">
          <span className='text-center text-black-500 font-bold'>
            {popUpTitle != '' &&  popUpTitle }
          </span>
          <span className='text-center text-xs text-black-500'>
            {popUpText }
          </span>
          <button 
              onClick={()=>setPopUpText('')} 
              className='text-center text-sm p-1 px-5 font-bold uppercase bg-gray-300 text-black-500'>
             Cerrar
          </button>
        </div>
      </div>
    )
  }

  const getUniqueConfig = (tipo = '') => configs.find(item => item.tipo === tipo )?.valor || '' 


  useEffect(()=>{ 
    context.CheckCacheLogin()
  },[])


  useEffect(()=>{ 
    if(selectedCard != '' && selectedCard != null){
      setIsOpenOneCardModal(true)
    } else {
      setIsOpenOneCardModal(false)
    }
  },[selectedCard])
  

  useEffect(() => { 
    GetGeneralConfigs()
    GetSorteoActual()
    GetCartones() 

  }, [sorteoActual, data ]);

  useEffect(() => { 
    const cartDB = cardList.filter((carton)=>( carton.id_cliente == context.user.clientId ))
    setCart(cartDB)
    setRenderCart(cartDB)  
    //console.log('cartDB',cartDB);
    
  }, [cardList, context]); 
  
  useEffect(() => {
    setData(context.user) 
  }, [context])
  
  useEffect(()=>{
    GetCartones()
  },[modalCart]) 
  
  useEffect(()=>{
    if(filterText > 0 ){ 
      
       setFilteredCardList(cardList.filter((item)=>{
         if(item.serial.slice(-4).includes(filterText)){
           return true
         }
         return false
       }))
      //console.log('filtrados:',filteredCardList);
      
    } 
    
  },[filterText])
  useEffect( () => {
      // obtener datos de las congifuraciones iniciales 
    setVentasStatus( getUniqueConfig('ventas_status') == '1' ) 
    if(getUniqueConfig('ventas_status') != '1' ){
      setCardList([])
      setFilteredCardList([])
    }
  }, [configs]);
  
  return ( 
    <Dashboard>
      {popUpText != '' && <PopUpDialog /> }
      {
        !ventasStatus && 
        <h2 className='text-white text-center text-2xl mt-5'>Las ventas para el sorteo {sorteoActual} han sido cerradas </h2>
      }
      {ventasStatus  && 
      <div className='fixed top-0 lef-0 right-0 flex flex-col w-full bg-[#222] pt-2 z-10'> 
        <h2 className='text-white text-center text-2xl mt-5'>Cartones Disponibles</h2>
        <div className='relative text-white text-right mr-4 flex gap-2 justify-center'>
          <span>MOSTRAR DATOS CARTON</span>
          <input 
            type="checkbox" 
            name="showNumbers" 
            id="showNumbers"  
            onChange={()=>setListMode(!listMode)}
            checked={!listMode}
            />
        </div> 
        <div className='relative text-right mr-4 flex gap-2 justify-center'> 
          <input 
            ref={filterTextRef}
            className='w-10/12 p-2 mb-2'
            type="number" 
            name="filterCards" 
            id="filterCards" 
            onChange={(e)=>handleFilterText(e.target.value)}
            value={filterText}
            
            />
        </div>
      </div>}


     {ventasStatus && <div
        ref={containerRef}
        onScroll={handleScroll}
        className=' h-[90vh] w-full mt-[150px]'
      >
        {cardList.length === 0 && isLoading ? (
          <CartonLoader />
        ) : (
          <div className='flex flex-wrap gap-2 justify-center z-0 cursor-pointer'>
            {listMode ? 
              <SimpleCard
                Sorteo={sorteoActual} 
                Cartones={filterText > 0 ? filteredCardList :cardList} 
                onClick={addToCart}
                renderCart={renderCart} 
                onSelectedCard={setSelectedCard}
              />
              : 
              <CompleteCard
                Sorteo={sorteoActual} 
                Cartones={filterText > 0 ? filteredCardList :cardList} 
                onClick={addToCart}
                renderCart={renderCart} 
              />
            } 
          </div>
        )}
      </div>}

      {!modalCart && renderCart.length > 0 && !modalPagar && ventasStatus &&
        <button
          className='h-10 w-10 bg-purple-500 z-30 fixed top-[85%] right-2 rounded-full mr-4 mb-4'
          onClick={handleModalCart}
        >
          <span className='absolute font-bold bg-red-400 w-6 h-6 -mt-4 -right-3 rounded-full text-white flex items-center justify-center border-solid border-2 border-red-400'>
            {renderCart.length}
          </span>
          <span className='text-white'><FontAwesomeIcon icon={faCartShopping} /></span>
        </button>
      }


      {modalCart && renderCart.length > 0 &&
        (
          <div className="backdrop-blur-sm z-10  fixed top-0 left-0 right-0 bottom-[50px] transition-transform duration-100 flex justify-end ">
            <div className="flex w-[19rem] bg-transparent pt-[70px] bg-red-500 ">
              <div className='flex flex-col w-full h-full justify-between  '> 
                <div className='flex flex-row h-[50px] w-full justify-between items-center py-4 px-2 mb-0 bg-[purple]' >
                  <h2 className='text-white'>
                    Carrito
                  </h2>
                  <button onClick={() => setModalCart(false)}>
                    <span>
                      <FontAwesomeIcon icon={faX} color='#fff' />
                    </span>
                  </button>
                </div> 
                <div className='w-full h-full bg-white flex flex-col justify-between  '>
                    <Cart
                      renderCart={renderCart}
                      setCart={setCart}
                      sorteoActual={sorteoActual}
                      setModalPagar={setModalPagar}
                      setModalCart={setModalCart}
                      removeItem={removeFromCart}
                      clearItems={removeFromCart}
                      configs={configs}
                    /> 
                </div>
              </div>
            </div>
          </div>
        )
      }

    { isOpenOneCardModal &&
      <GetCarton
        cartonSerial={selectedCard}
        isOpen={isOpenOneCardModal}
        isClose={()=>setSelectedCard('')}
      />
    }
      {modalPagar &&

        <div className=" backdrop-blur-sm z-10  fixed top-0 left-0 right-0 h-full transition-transform duration-100 flex justify-center items-center ">
          <div className=" flex w-[95%] md:w-[75%] h-[75%] bg-white rounded">
            <div className='flex flex-col w-full'>
              <div className='w-full h-[50px] flex justify-between  pt-3 px-3'>
                <h3>Proceso de Pago</h3>
                <button
                  onClick={() => setModalPagar(false)}> 
                  <FontAwesomeIcon icon={faX} color='#f00' /> 
                </button>
              </div>
                <AutoPago 
                  setModalPagar={setModalPagar} 
                  carrito={cart}  
                  orderNumber={order}
                  sorteoActual={sorteoActual} 
                  configs={configs}
                  /> 
            </div>

          </div>
        </div>
      }
    </Dashboard>
  );
}

export default CardList
