/* eslint-disable */
import React, { useContext, useEffect } from 'react'
import AuthContext from '../../context/AuthContext'
import { use } from 'react'
import { Dashboard } from '..'
import { useNavigate } from 'react-router-dom'


const UserProfile = () => {
  const context = useContext(AuthContext)
  const { user } = useContext(AuthContext)

  console.log(user);
  
  const whatsappPhone = '+34641420931'
  const whatsappMessage = 'Hola. Quisiera modificar mis datos de BingoVe'
  const whatsappLink = `https://wa.me/${whatsappPhone}?text=${whatsappMessage}`

  const navigate = useNavigate();
  
  useEffect(()=>{
    //console.log('user',user)
  },[])

  return (
    <Dashboard> 
      <div className='flex flex-col items-center'>
        <div className='flex flex-col items-center bg-white border mt-24 mx-10 w-[90%] p-2 rounded-lg'>
          {/* <h1 className='max-sm:text-3xl max-lg:text-3xl font-bold pt-4 text-center'>Perfil de {user.name}</h1> */}
          <section className='flex flex-col w-[95%] gap-2.5 items-center'>
            <h2 className='max-sm:text-xl max-lg:text-2xl text-center'>Información Personal</h2>

            <div className='w-[90%] p-2 flex justify-around items-center text-md rounded bg-gray-200'>
              <label className='font-medium'>Nombre:</label>
              <article className='font-semibold'>{user.name}</article>
            </div>

            <div className='w-[90%] p-2 flex justify-around items-center text-md rounded bg-gray-200'>
              <label className='font-medium'>Usuario:</label>
              <article className='font-semibold'>{user.username}</article>
            </div>

            <div className='w-[90%] p-2 flex justify-around items-center text-md rounded bg-gray-200'>
              <label className='font-medium'>Teléfono:</label>
              <article className='font-semibold'>{user.telefono}</article>
            </div>

            <div className='w-[90%] p-2 flex justify-around items-center text-md border rounded bg-gray-200'>
              <label className='font-medium'>Cédula:</label>
              <article className='font-semibold'>{user.documento}</article>
            </div>

            <div className='w-[90%] p-2 flex justify-around items-center text-md border rounded bg-gray-200'>
              <label className='font-medium'>Promotor:</label>
              <article className='font-semibold'>{user.promotorId}</article>
            </div>

            <div className='w-[90%] p-2 flex justify-around items-center text-md border rounded bg-gray-200'>
              <label className='font-medium'>Cuenta Personal:</label>
              <article className='font-semibold text-right'>{user.datos_pago?.replace("-", "")}</article>
            </div>

            

          </section>

            <article className='flex self-end my-2.5'>
              <button className='rounded-md p-1 bg-[purple] hover:bg-purple-950' type="button">
                <a
                  href={whatsappLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className='no-underline text-white font-bold'
                >Soporte
              </a>
              </button>
          </article>

          <button 
            className='rounded-md p-1 bg-[purple] hover:bg-purple-950' 
            type="button"
            onClick={()=> {
              context.logout()
              navigate('/home')
            }}
          > 
          <span className='no-underline text-white font-bold p-3'>Cerrar Sesion</span>
                
              </button>

        </div>
      </div>
    </Dashboard>
  )
}

export default  UserProfile
