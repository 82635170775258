/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react'
import { useFormikContext, Field } from 'formik'
import axios from 'axios'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Toaster, toast } from 'react-hot-toast'
import { set } from 'react-ga';
const baseURL = process.env.REACT_APP_URL_API_V2

function PersonalInfo({ handlerPhone, handlerCountry, handlerNombre, isValid, dirty, onConfirmData, onChangeOperadora, referido, tipoReferido }) {
  const { errors, touched } = useFormikContext()
  const [countryCode, setCountryCode] = useState('+58')
  const [phoneNumber, setPhoneNumber] = useState('')
  const [codeVE, setCodeVE] = useState(true)
  const [operadoraVE, setOperadoraVE] = useState('424')
  const [maxDigits, setMaxDigits] = useState(7)
  const [warning, setWarning] = useState(false);
  const [isRef, setIsRef] = useState(false)
  const [refBy, setRefBy] = useState('')
  const [refType, setRefType] = useState('')
  const [isValidForm, setIsValidForm] = useState(isValid)
  const [isDirty, setIsDirty] = useState(dirty)

  const submitRef = useRef(null)
  const { setFieldValue } = useFormikContext();

  const handleOperadora = (operadora) => {
    if (onChangeOperadora) {
      onChangeOperadora(operadora)
    }
    setOperadoraVE(operadora)
  }
  const checkCountryCode = (value, country) => {
    console.log('value', value)
    console.log('country', country);
    setCountryCode(country.dialCode)
    if (country.dialCode != '58') {
      setCodeVE(false)
    } else {
      setCodeVE(true)
    }
  }

  console.log(referido);
  console.log(tipoReferido);


  const sanitizeInput = (value) => {




    const sanitizedPhone = value.replace(/[^0-9]/g, '');
    console.log('sanitizedPhone', sanitizedPhone);
    setFieldValue("phoneNumber", sanitizedPhone);

    setPhoneNumber(sanitizedPhone)
    handlerPhone(sanitizedPhone)

  };


  const handlePhoneValue = (value) => {
    const sanitizedPhone = value.replace(/[^0-9]/g, '')
    console.log('sanitizedPhone', sanitizedPhone);

    setPhoneNumber(value)
    handlerPhone(operadoraVE + value)
  }


  const handleCountryValue = (value, country) => {
    setCountryCode(value)
    handlerCountry(value)
    checkCountryCode(value, country)

  }

  const handleNombreValue = (value) => {

    handlerNombre(value)
  }


  const handleConfirmData = async (phone, code, operadora) => {
    //aqui se necesita sin 00 adelante ni + porque va a confirmarse en base de
    const tempCode = countryCode.replace('00', '').replaceAll('+', '')
    let response = await axios.get(`https://api.bingove.com/v2/users/verifyPhone/${tempCode}/${phone}`)
    if (tempCode == '58' || tempCode == '+58') {
      response = await axios.get(`https://api.bingove.com/v2/users/verifyPhone/${tempCode}/${operadora}${phone}`)
    }
    if (response.data.status == 1) {
      if (onConfirmData) {
        if (tempCode == '58' || tempCode == '+58') {
          onConfirmData(submitRef, countryCode, operadora, phone)
        } else {
          onConfirmData(submitRef, countryCode, '', phone)
        }
      }
    } else {
      alert(response.data.status_message)
    }
  }

  useEffect(() => {
    setIsValidForm(isValid)
    setIsDirty(dirty)
  }, [dirty, isValid])

  useEffect(() => {
    if (codeVE && warning) {
      toast.error('solo debes colocar los ultimos 7 digitos(sin 0424, 0416, Etc...)', { duration: 10000 })
    }
  }, [warning, codeVE])

  useEffect(() => {
    if (referido != null) {
      setIsRef(true)
    }
    else {
      setIsRef(false)
    }
  }, [referido])
  return (
    <>
      {isRef && (
        <div>
          <p className='font-bold'>**fue referido por el {tipoReferido} Nº {referido}**</p>
        </div>
      )}
      <div className='flex flex-col gap-2'>
        <label className="block text-sm font-medium text-gray-700">
          Nombre y Apellido
        </label>
        <Field
          validate={handleNombreValue}
          name="name"
          type="text"
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]"
          placeholder="Ingrese su nombre completo"
        />
        {errors.name && touched.name && (
          <p className="text-red-500 text-sm mt-1">{errors.name}</p>
        )}

        <label className='block text-sm font-medium text-gray-700'>
          Número de Teléfono
        </label>
        <div className='flex gap-2'>
          <div className="w-full flex gap-1 items-center ">
            <div className='w-[25%] h-10'>
              <PhoneInput
                className="w-full  h-10"
                country={'ve'}
                countryCodeEditable={false}
                onChange={handleCountryValue}
                buttonStyle={{ backgroundColor: 'transparent', border: 'none' }}
                //onlyCountries={['ve','es','us','dom', 'br', 'co','ar','pe']}
                inputStyle={{ width: "100%", height: '40px', paddingLeft: 35, background: "transparent" }}
                inputProps={{
                  readOnly: true,
                }}
              />
            </div>
            {codeVE && (
              <div className="w-3/12">
                <select
                  className="w-full px-1 py-2 border rounded-md  hover:cursor-pointer focus:outline-none focus:ring focus:ring-[purple] h-10"
                  name=""
                  id=""
                  onChange={(e) => handleOperadora(e.target.value)}
                  value={operadoraVE}
                >
                  <option value="424">0424</option>
                  <option value="414">0414</option>
                  <option value="416">0416</option>
                  <option value="426">0426</option>
                  <option value="412">0412</option>
                </select>
              </div>)
            }

            <div className={codeVE ? " w-6/12 " : " w-10/12 "}>
              <Field
                //validate={handlePhoneValue} 
                name="phoneNumber"
                type="text"
                //maxLength={maxDigits}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple] h-10"
                placeholder="0000000"
                onChange={(e) => {
                  sanitizeInput(e.target.value)
                  setWarning(e.target.value.length > maxDigits)
                }}
              />
            </div>
          </div>
        </div>
        {errors.countryCode && touched.countryCode && (
          <span className='text-red-500 text-sm mt-1'>{errors.countryCode}</span>
        )}
        {errors.phoneNumber && touched.phoneNumber && (
          <span className='text-red-500 text-sm mt-1'>{errors.phoneNumber}</span>
        )}

      </div>
      <button
        ref={submitRef}
        type="submit"
        className=" hidden"
        disabled={!isValidForm || !isDirty}
      >
      </button>
      <button
        type="button"
        className="rounded-lg mt-3 border-2 py-1 w-full font-[600] uppercase bg-[purple] text-white hover:bg-purple-950 disabled:bg-gray-500"
        disabled={!isValidForm || !isDirty}
        onClick={() => handleConfirmData(phoneNumber, countryCode, operadoraVE)
        }
      >
        Siguiente
      </button>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          style: {
            marginTop: 50
          },
        }}
      />
    </>
  )
}



export default PersonalInfo
