import axios from 'axios'
const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API_V2
})

export const apiAny = axios.create({
  baseURL: process.env.REACT_APP_URL_API_V1
})

export default api

export const getApi = async (endpoint, body, headers = null) => {
  try {
    const dataHeaders = headers || { 'Content-Type': 'aplication/json' }
    const response = await api.get(endpoint, {
      headers: dataHeaders,
      body: body ? JSON.stringify(body) : undefined
    })
    return response
  } catch (error) {
    console.error('[ERROR: GET_API]', error)
    return {}
  }
}

export const postApi = async (endpoint, body, headers = null) => {
  try {
    const dataHeaders = headers || { 'Content-Type': 'aplication/json' }
    const response = await api.post(endpoint, {
      headers: dataHeaders,
      body: body ? JSON.stringify(body) : undefined
    })
    return response
  } catch (error) {
    console.error('[ERROR: GET_API]', error)
    return {}
  }
}

export const updateUser = async (username, userData) => {
  console.log('userData', userData)
  try {
    const response = await api.post(`/users/${username}`, userData)
    return response.data
  } catch (error) {
    console.error('Error al actualizar usuario:', error)
    throw error
  }
}
// Verbo GET
// api.get(endpoint)

// Verbo POST
// api.post(endpoint, dados)

// Verbo DELETE
// api.delete(endpoint, dados)

// Verbo PUT
// api.put(endpoint, dados)
