/* eslint-disable */

import React, { useEffect, useState } from "react";
import api from "../services/api";  
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader 
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';  
import { isMobile } from "react-device-detect";
import Iframe from "react-iframe";
const baseURL = process.env.REACT_APP_URL_API_V2
const frontURL = process.env.REACT_APP_URL_FRONTEND
export function formatearFecha(fecha) {
    const date = new Date(fecha);
    const dia = date.getDate();
    const mes = date.getMonth() + 1; // Los meses van de 0 a 11
    const año = date.getFullYear() % 100; // Obtiene los últimos dos dígitos del año
  
    // Agregar un cero delante si el día o el mes tienen un solo dígito
    const diaFormateado = dia < 10 ? `0${dia}` : dia;
    const mesFormateado = mes < 10 ? `0${mes}` : mes;
  
    return `${diaFormateado}/${mesFormateado}/${año}`;
}

export const getConfigValue = (configs, key )=>{
    return configs.find(item => item.tipo === key ).valor
}
export const GetAllConfigs = async () =>{
    let hanldeConfigs = []
    await  api.get('/read/configs')
     .then((response) =>{ 
        if(response?.data?.content.length > 0)
        { 
            hanldeConfigs = response?.data?.content; 
        } 
      })
   .catch((err) => console.error('error: ' + err)); 
   return hanldeConfigs

   /*
    array con los elementos tipo json
    {
        id: number;
        tipo: string;
        valor: string;
        extra: any;
    }
        se debe pedir con un find  con el tipo a buscar ejemplo 
        configs.find(item => item.tipo == 'tipo')
        const sorteoActual =  configs.find(item => item.tipo === 'sorteoActual' ).valor
    */
 }


 //ESTOS SON FUTUROS COMPONENTES
export const VideoYotube = ({title, url,color})=>{
    let width_video = isMobile? "320" : "560"
    let height_video = isMobile? "380" : "340"
    return (
        <div  
            className="border-solid border-[4px] border-[#9900cc] mt-[24px] 
                h-[240px] lg:h-[340px] w-[320px lg:w-[560px] text-[white] flex flex-col justify-start 
                items-center font-bold text-[24px]" >
            {
            title && 
            <span className="absolute -mt-[50px]" style={{color: color || "white"}}>
                {title}
            </span>
            }
            <Iframe
                src= {"https://www.youtube.com/embed/"+url}
                width={width_video}
                height={height_video}
                title={"YouTube-" + title }
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                allowfullscreen               
            />
        </div>
    
    );
} 
export const RedesSociales = ()=>{
    const iconSize = isMobile ? "35" : "20"
    return (<>
        <div 
            className="pt-[20px] flex flex-wrap font-bold text-[18px] justify-center items-center gap-x-[50px] 
            gap-y-[15px] lg:gap-[50px] min-h-[50px] w-[100%] bg-[linear-gradient(0deg,#000 0%, #1a1a1a 100%)]  "
            >
            <a 
                className="no-underline gap-[10px] text-[white] flex justify-center items-center" 
                href="https://www.instagram.com/bingo_ve1.0/" 
            
            > 
                <img src={frontURL + "/images/instagram_white.png"} height={iconSize} width={iconSize} /> 
                { 
                    !isMobile &&
                    <span>bingo_ve1.0</span>
                }               
            </a>

            <a 
                className="no-underline gap-[10px] text-[white] flex justify-center items-center"
                href="https://www.facebook.com/bingove1.0" >
                <img src={frontURL + "/images/facebook_white.png"} height={iconSize} width={iconSize} />  
                { 
                    !isMobile &&
                    <span>bingo_ve1.0</span>
                } 
            </a>

            <a 
                className="no-underline gap-[10px] text-[white] flex justify-center items-center"
                href="https://www.youtube.com/@bingo_ve" >
               <img src={frontURL + "/images/youtube_white.png"} height={iconSize} width={iconSize} />  
                { 
                    !isMobile &&
                    <span>@bingo_ve</span>
                } 
            </a>
        </div>
    </>)
} 
export const HomeButtons = ()=>{
    const CustomButtom = ({url,icon,title1,title2})=>{
        const iconSize = isMobile ? "40" : "50"
        return (<>
        
            <a 
                href={url} 
                className=" no-underline flex-col text-center lg:text-left lg:flex-row gap-[10px] text-[white] flex justify-center items-center bg-[#333]
                            p-[5px] pl-[20px] pr-[20px] rounded-[10px] cursor-pointer" 
                > 
                <img src={frontURL + "/images/"+icon+".png"} height={iconSize}  width={iconSize} />                
                <span className="">{title1} <br /> {title2}</span>
            </a>
        
        </>)
    }
    return (<>
        <div 
            className=" h-[auto]  lg:h-[50px] flex w-full justify-center items-center gap-[10px]
                font-bold text-[18px] mt-[30px] mb-[30px] flex-wrap lg:gap-[50px]"
            >
            <CustomButtom title1={"Cartones"} title2={isMobile ?"":"Disponibles"} url={"/cartones"} icon={"bola_cartones"}/>
            <CustomButtom title1={"Encuentra"} title2={isMobile?"":"un promotor"} url={"/promotores"} icon={"bola_promotor"}/>
            <CustomButtom title1={"Ver sorteo"} title2={isMobile?"":"en vivo"} url={"/sorteo"} icon={"bola_sorteo"}/>                
            {
                promotorEmpleo &&
                <CustomButtom title1={isMobile?"Empleo":"Trabaja con"} title2={isMobile?"":"nosotros"} url={"/empleo"} icon={"bola_trabaja"}/>
            }
            <CustomButtom title1={"Preguntas"} title2={isMobile?"":"frecuentaes"} url={"/preguntas"} icon={"bola_preguntas"}/> 
            
        </div>
    </>)
}
export const Espaciador = ({height})=>{
    return(<div className="w-full" style={{minHeight:height}}>

        </div>
    )
}
export const WhatsappButton = () => {
    const handlerGoToWhatsapp = () => { 
        console.log('redireccion')
        window.location.href = frontURL + '/info'
        
    }
    return (<>
    
    <button 
        className="animate-pulse bg-[green] text-white font-[600] py-2 px-2 flex justify-center items-center text-center text-3xl"
         onClick={handlerGoToWhatsapp}
        >
        <img className=" " src="/images/WhatsApp.webp" width={80} />
        <span className="w-[70%]  animate-bounce"> 
            COMPRA TU CARTON AQUI
        </span>
    </button>
    </>)
}
 
 export const Flotante = ({name,icon,position,action}) => { 
    const handlerAction = () =>{
        if(action){
            console.log('accion',name)
            action()
        }
    }
    return (<> 
        <button 
            className={`fixed z-[10000] hover:w-[90px] w-[80px] h-[80px]  py-2 px-2 flex justify-center items-center  ${position}`}
            onClick={handlerAction} 
            >
            <img src={`/images/${icon}`} width={80} height={80} /> 
        </button>
    </>)
}
export const Sorteos = ({sorteo}) =>{

    const rondas = ["Ronda 1","Ronda 2"]
    const bolitas = [74,65,63,33,13,73,71,28,39,72,21,59,18,24,51,8,56,41,32,15,53,17,52,61,38,40,14,10,30,46,11,57,2,60,66,75,54,42,5,1,37,62,12,6,27,50,47,7,48,29,69,36,35,67,25,45,34]
    const Juegos = ["Esquinas","Diagonal","Diagonal Invertido","Cruz","Linea Horizontal","Linea Vertical"]
    return (<>

    <div>
        <hr style={{borderColor:"#fff",width:"700px"}}/>
        <h2 style={{color:"#fff",fontSize:26}}>
            <b>RESULTADOS DE LOS SORTEOS</b>                
        </h2>
        <Espaciador height={20} />
        <SelectorSorteos />
        <Espaciador height={50} />
    </div>

    <div style={
        {
            width:"100%",
            height:"auto",
            display:"flex",
            justifyContent:"center",
            color:"white"
        }}>

        <div style={{width:"80%",height:"auto",minHeight:"30px",background:"#8932ab"}}>
        <Tabs>
            <TabList>
                {rondas.map((ronda)=>{
                    return <Tab><b>{ronda}</b> </Tab>
                })}
            </TabList>
            {rondas.map((ronda,index)=>{
                return (<TabPanel>
                    <div style={{padding:50,display:"flex",flexDirection:"column",alignItems:"center"}} key={"ronda-index-"+index} >
                        <h2>Bolitas en orden de salida</h2>
                        <div style={{flexWrap:"wrap",gap:"5px", display:"flex",padding:20}}>
                            {bolitas.map((bola,index)=>{
                                let bolaBG = "url('images/bola_gris.png')"
                                if(index == bolitas.length - 1 ){
                                    bolaBG = "url('images/bola_verde.png')"
                                }
                                return (<>
                                <div style={
                                    {
                                        background:bolaBG,
                                        backgroundSize:"100%",
                                        height:40,
                                        width:40,
                                        color:"#000",
                                        fontWeight:"bold",
                                        display:"flex",
                                        justifyContent:"center",
                                        alignItems:"center"
                                    }}>
                                    <span>{bola}</span>
                                </div>
                                </>
                                )
                                //key={"ronda-index-"+index} 
                            })}
                        </div>
                        <div style={
                            {
                                display:"flex",
                                flexDirection:"column",
                                width:"100%",
                                borderLeft:"solid 1px white",
                                borderRight:"solid 1px white",
                                borderBottom:"solid 1px white",
                                borderRadius:22,
                                alignItems:"center",
                                paddingBottom:10,
                                minHeight:200
                            }}>
                            <div style={
                                {
                                    border:"solid 1px white", 
                                    fontSize:20,
                                    color:"#ffcc00",
                                    background:"green",
                                    width:"100%",
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center",
                                    paddingTop:10,
                                    paddingBottom:10,
                                    borderRadius:20,
                                    textTransform:"uppercase"
                                }}>
                                <span> <b>CARTON LLENO {ronda}</b> </span>
                            </div>
                            <div style={{height:"auto",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}>
                                <Espaciador height={20} />
                                <CartonNew />
                                <Espaciador height={20} />
                                <span><b>{sorteo}</b> </span>
                            </div>
                        </div>
                        <Espaciador height={20} />
                        <span style={
                            {
                                fontSize:20,
                                color:"#ffcc00",
                                textTransform:"uppercase"
                            }}> 
                            <b>OTROS PREMIOS DE LA {ronda}</b> 
                        </span>
                        <Espaciador height={20} />
                        <div style={
                            {
                                borderRadius:10,
                                padding:10
                            }}>
                            <Tabs>
                                <TabList>
                                    {Juegos.map((item)=>{
                                        return (<Tab><b>{item}</b> </Tab>)
                                    })}
                                </TabList>
                                
                                    {Juegos.map((item)=>{
                                        //contenido de las rondas
                                        return (<TabPanel>
                                            <div style={
                                                    {
                                                        display:"flex",
                                                        flexDirection:"column",
                                                        width:"100%",
                                                        borderLeft:"solid 1px white",
                                                        borderRight:"solid 1px white",
                                                        borderBottom:"solid 1px white",
                                                        borderRadius:22,
                                                        alignItems:"center",
                                                        paddingBottom:10,
                                                        minHeight:200
                                                    }}>
                                                    
                                                    <div style={{height:"auto",display:"flex",flexDirection:"column",justifyContent:"flex-start",alignItems:"center"}}>
                                                        <Espaciador height={20} />
                                                        <CartonNew />
                                                        <Espaciador height={20} />
                                                        <span><b>{sorteo}</b> </span>
                                                    </div>
                                                </div>
                                            
                                            </TabPanel>)
                                    })}
                            
                            </Tabs>
                        </div>
                    </div>
                </TabPanel>)
            })}
        </Tabs>

        </div>
    </div>
    </>)
} 

export const generateCode = () => {
    return Math.floor(100000 + Math.random() * 900000);
}

export const getUniqueConfig = (tipo = '', configs = []) => configs.find(item => item.tipo === tipo )?.valor || '' 