/* eslint-disable */
import React from "react";
import { isMobile } from "react-device-detect";
import Iframe from 'react-iframe'

function Youtube({ link, expand }) {
  return (
    <div className={`youtube-wrapper ${expand ? 'expanded' : ''}`}>
      <Iframe 
        src={`https://www.youtube.com/embed/${link}`}
        width="100%"
        height={200}
        styles={{
          aspectRatio: '16/9' 
        }}
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
        allowfullScreen               
      />
    </div>
  );
}

export default Youtube;