/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"; 
import '../../App.css';
import './Carton.css'; 
import AuthContext from "../../context/AuthContext";

function CompleteCard({ Cartones, Sorteo, renderCart, onClick }) { 
    const context = useContext(AuthContext)
    const [data, setData] = useState({})
  
    

    const handleOnClick = ( datos )=>{
        if(onClick){
            onClick( datos )
        }
    }

    
    useEffect(() => {
        setData(context.user)
        console.log(context.user)
    }, [])
    
    
    return ( Cartones
        .map((carton)=>{

            const { numero, serial, id_cliente, datos } = carton  
            const isSelected = id_cliente == data.clientId  
            const numeroCarton = serial.slice(13, 17)
            let Formateado = datos?.replaceAll("[","") 
            let numeros = Formateado?.replaceAll("]","").split(",") 
            return (
                <div key={'carton-serial-' + numeroCarton} 
                    style={{height: 160}}
                    className={ [ isSelected && ' selectedCompleteCard ']} 
                    onClick={()=> handleOnClick(carton)}>
                        <div className="complete_carton_container" style={{height: 160}}> 
                            <div className="carton_head text-white">
                                <span>CARTON {numeroCarton}</span> 
                            </div>
                            <div  className="carton_sorteo" >
                                <span className="carton_sorteo_span">
                                    {Sorteo}
                                </span>
                            </div>  
                            <div className="carton_content_grid ">
                            {
                                numeros?.map((numero,index)=>{
                                    
                                    if(index == 12){
                                        return (<span className="carton_number_free" key={'carton-tabla-' + index}>
                                            LIBRE
                                        </span>)
                                    }
                                
                                    return (
                                    <span 
                                        className="carton_number_normal" 
                                        key={'carton-tabla-' + index}
                                        >
                                        {numero}
                                    </span>) 

                                })
                            }
                            </div>
                        </div> 
                </div> 
            ) 
        }
    ) )
  }
  
  export default CompleteCard;