/* eslint-disable */
import React,{useState,useEffect, useContext} from 'react'
import { useFormikContext, Field } from 'formik'
import AuthContext from '../../context/AuthContext'

function OptionalInfo (isValid,dirty,onConfirmData,onCancel, onMetodoPago) {
  const context = useContext(AuthContext)
  const { errors, touched } = useFormikContext()
  const [acceptTerms, setAcceptTerms] = useState(false)
  const [isValidForm, setIsValidForm] = useState(isValid)
  const [isDirty, setIsDirty] = useState(dirty)
 
  const handleMetodoPagoValue = (value) =>{
    //alert('cambio a '+value)
    //alert('contexto antes '+context.metodoPago)
    //context.metodoPago = value 
    localStorage.setItem('metodoPago',value)
    //alert('contexto despues '+context.metodoPago)
  }
  const handleConfirmData = () => {
    if(onConfirmData){
      onConfirmData()
    }
  }
  const handleCancel= () => {
    if(onCancel){
      onCancel()
    }
  }
  useEffect(()=>{
    setIsValidForm(isValid)
    setIsDirty(dirty)
  },[dirty,isValid])

  return (
    <>
      <div className="my-2">
        <label className="block text-sm font-medium text-gray-700">
          Cédula de Identidad / pasaporte (Requerido)
        </label>
        <Field
          name="cedula"
          type="number"
          className="w-full px-3 py-2 border rounded-md mt-2 focus:outline-none focus:ring focus:ring-[purple]"
          placeholder="Ingrese su documento de identidad"
        />
        {errors.cedula && touched.cedula && (
          <p className="text-red-500 text-sm mt-1">{errors.cedula}</p>
        )}
      </div>

      <div className="my-2">
        <label className="block text-sm font-medium text-gray-700">
          Método de Pago Preferido
        </label>
        <Field
          validate={handleMetodoPagoValue}
          name="metodoPago"
          as="select"
          className="w-full px-3 py-2 border rounded-md mt-2 hover:cursor-pointer focus:outline-none focus:ring focus:ring-[purple]"
        >
          <option value="">Seleccione un método</option>
          <option value="pagomovil">PagoMóvil</option> 
          <option value="ahorro">Transferencia bancaria (ahorro)</option> 
          <option value="corriente">Transferencia bancaria (corriente)</option> 
          <option value="otro">Otro método de pago (especificar)</option>
        </Field>
      </div>

      <div className="my-2">
        <label className="block text-sm font-medium text-gray-700">
          Datos de Pago (Requerido)
        </label>
        <Field
          name="datosPago"
          as="textarea"
          className="w-full px-3 py-2 border rounded-md mt-2 focus:outline-none focus:ring focus:ring-[purple]"
          placeholder="Ingrese los detalles de pago"
          
        />
        {errors.datosPago && touched.datosPago && (
          <p className="text-red-500 text-sm mt-1">{errors.datosPago}</p>
        )}
      </div>

          <span className="block text-sm font-medium my-2 text-gray-500">
            *** NOTA: La cuenta bancaria donde le pagaremos podrá ser configurada en su panel de usuario cuando gane por
            primera vez. ***
          </span>
      <div className="my-2 flex gap-2 items-center">
        <Field
          name="terminos"
          type="checkbox"
          className="h-5 w-5"
        />
        <label className="block text-sm font-medium text-gray-700">
          Acepto los{' '}
          <span>
            Términos y condiciones
          </span>
        </label>
       
      </div>
      {errors.terminos && touched.terminos && (
          <p className="text-red-500 text-sm mt-1">{errors.terminos}</p>
        )}
      <div className="flex justify-between mt-4"> 
        
          <button
            type="submit"
            disabled={!isValidForm || !isDirty}
            onClick={handleConfirmData}
            className="rounded-lg border-2 py-1 w-full font-[600] uppercase ml-4 bg-[purple] text-white hover:bg-purple-950 disabled:bg-gray-500 "
          >
            COMPLETAR REGISTRO
          </button> 
      </div> 
</>
  )
}

export default OptionalInfo
