/* eslint-disable */
/* global alert */
/* global localStorage */
import React, { useCallback, useEffect, useRef, useState, version } from "react";
import api from "../../services/api";
import {Header,Footer,Youtube,Carton,Tablero,Chat} from '../../components';
import './ViewSort.css';
import Select , { components } from 'react-select';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faExpand, faInfo, faMinimize, faStar, faUsers} from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Accordion from 'react-bootstrap/Accordion';
import 'bootstrap/dist/css/bootstrap.min.css';
import { isMobile } from "react-device-detect";
import { useLocation } from 'react-router-dom';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from 'react-tooltip'

import ReactGA, { set } from 'react-ga';
import { Dashboard } from "..";
import { GetAllConfigs, getConfigValue } from "../../helpers/helpers";

<FontAwesomeIcon icon={faUsers} />


function ViewSort() {
    const location = useLocation();
    const CARTONSAVED = window.localStorage.getItem('cartonesSaved')

    const [configs, setConfigs] = useState([]);
    const [link, setLink] = useState();
    const [vendidos,setVendidos] = useState();
    const [TotalRepartir, setTotalRepartir] = useState(0)
    const [BaseTotalRepartir, setBaseTotalRepartir] = useState(1.00)
    const [sorteoActual, setSorteoActual] = useState(); 
    const [PremiosLittle, setPremiosLittle] = useState(0)
    const [PremiosBig, setPremiosBig] = useState(0) 
    const [publicidad, setPublicidad] = useState(0)
    const [fecha, setFecha] = useState();
    const [dia, setDia] = useState(); 
    const [rondas, setRondas] = useState(2);
    const [precioCarton, setPrecioCarton] = useState(60);
    const [totalPremios, setTotalPremios] = useState(5);
    const [ventasStatus, setVentasStatus] = useState(false);  

    const [Cartones,setCartones] = useState([]);
    const [serialToAdd,setSerialToAdd] = useState();       
    const [youtubeExpand,setYoutubeExpand] = useState(false); 
    const [Ganadores_1,setGanadores_1] = useState([]);
    const [Ganadores_2,setGanadores_2] = useState([]);
    const [Ganadores_3,setGanadores_3] = useState([]);
    const [CartonesLocal,setCartonesLocal] = useState([])

    const [porcentajePromotor, setPorcentajePromotor] = useState(0.10)
    const [porcentajeAdmin, setPorcentajeAdmin] = useState(0.05)
    const [porcentajeOwner, setPorcentajeOwner] = useState(0.1)
    const [bonusPromotor, setBonusPromotor] = useState(0.05)

    const [BolasSorteo,setBolasSorteo] = useState([]);
    const [BolasList ,setBolasList] = useState([]);
    const [BallDelay,setBallDelay] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const input_add = useRef(null) 

    
    const GetGeneralConfigs = async () =>{
        const allConfigs = await GetAllConfigs()
        setConfigs( allConfigs )
        setLink( getConfigValue(allConfigs,'sorteo_youtube') )    
        setVendidos( getConfigValue(allConfigs,'cartones_vendidos') )
        setBaseTotalRepartir( getConfigValue(allConfigs,'premio_base_total') )
        setSorteoActual( getConfigValue(allConfigs,'sorteo_actual') )  
        setFecha( getConfigValue(allConfigs,'sorteo_fecha') )
        setDia( getConfigValue(allConfigs,'sorteo_dia') ) 
        setPrecioCarton( getConfigValue(allConfigs,'cartones_precio') )
        setVentasStatus( getConfigValue(allConfigs,'ventas_status') )
        setPublicidad( getConfigValue(allConfigs,'precio_publicidad') )
        setBallDelay( getConfigValue(allConfigs,'ball_delay') )
        setPorcentajePromotor( getConfigValue(allConfigs,'porcentaje_promotor') )
        setPorcentajeAdmin( getConfigValue(allConfigs,'porcentaje_admin') )
        setPorcentajeOwner( getConfigValue(allConfigs,'porcentaje_owner') )
        setBonusPromotor( getConfigValue(allConfigs,'bonus_promotor') )
        setTotalPremios( getConfigValue(allConfigs,'cantidad_premios') )
 
    }
 
    const GetCartones = async()=>{
        await  api.get('/read/sorteo_cartones')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempCartones= response?.data?.content  
                 setCartones(TempCartones)
                 //console.log("TempCartones" ,TempCartones);
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       }); 
    }
     
    const GetGanadores =  async () =>{
        await  api.get('/read/sorteo_datos')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempSorteo = response?.data?.content  
                 const GanadoresList1 = TempSorteo[0]?.ganadores_ronda_1
                 const GanadoresList2 = TempSorteo[0]?.ganadores_ronda_2
                 const GanadoresList3 = TempSorteo[0]?.ganadores_ronda_3
                 if(GanadoresList1){setGanadores_1(GanadoresList1?.split('-'))}
                 if(GanadoresList2){setGanadores_2(GanadoresList2?.split('-'))}
                 if(GanadoresList3){setGanadores_3(GanadoresList3?.split('-'))}
                 //console.log("TempSorteo",TempSorteo)
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       });
    } 

    const updateTotalRepartir = async() =>{  
        const premioS = BaseTotalRepartir / ( totalPremios  * rondas)
        setPremiosLittle( premioS ) 
        setPremiosBig(( premioS ) * 2 ) 
        setTotalRepartir(BaseTotalRepartir)  
    }
    const UpdateBolasSorteo = async() =>{
        await api.get('/read/sorteo_bolitas')
        .then((response) =>{ 
            if(response?.data?.content.length > 0){
                const TempData = response?.data?.content                
                const BolasParse = TempData[0]?.bolas?.split(",") 
                if(BolasList.length === 0){
                    setBolasList(BolasParse)
                } 
                setBolasSorteo(BolasParse);
                //console.log('UpdateBolasSorteo',BolasParse)
            }
        })
        .catch((err) => console.error( err));
    }


    const GetBolasSorteo = async() =>{ 
        if(BolasList.length > 0) { 
            //console.log('si hay bolitas',BolasList)
            let actuales = BolasList
            let cola = true  
            BolasSorteo.map( ( bolita, index ) => {
                if( bolita != actuales[index] ){
                    if(cola){ 
                        actuales.push(bolita)
                        cola = false
                    }
                }
                
            } ) 
            if(BolasSorteo.length == 1){
                setBolasList(BolasSorteo) 
            } else {
                setBolasList(actuales) 
            }
            
        }
    }  

    const expandbeHandler = () =>{
        setYoutubeExpand(!youtubeExpand)
    }
    const Premio = ({name = "Esquinas",premio = 0,size = "40",solid}) =>{
        let size_premio = size + "px"
        let size_title = "12px"
        let widthPremio = "140px"
        switch(size){
            case "small" : widthPremio = "140px" ; size_premio = 20 ; size_title = 12 ; break ;
            case "medium" : widthPremio = "250px" ; size_premio = 40 ; size_title = 18 ; break ;
            case "large" : widthPremio = "140px" ; size_premio = 180 ; size_title = 90 ; break ;
            default : break ;

        }
        const bg_gradient = solid? "#7f2e93":"linear-gradient(0deg,#7f2e93 0%,#7f2e93 20%,#00000000 100%)"
        return (<>
            <div style={
                {
                    background: bg_gradient,
                    height:(size_premio + 20  ) + "px" ,
                    width: widthPremio,
                    display:"flex",
                    alignItems:"center",
                    borderRadius:90,
                    justifyContent:"center"
                }}>
                <span style={
                    {
                        fontSize:12,
                        textAlign:"center",
                        display:"flex",
                        flexDirection:"column",
                        paddingTop:"5px",
                        paddingBottom:"5px",
                        lineHeight:  (size_title + 10) + "px"
                    }}>
                    <b style={{color:"white",fontSize: size_title + "px",marginTop:(-size_title -5 )+ "px"}}>{name}</b>
                    <b style={{color:"#ffcc00",fontSize: size_premio + "px"}}>{premio?.toFixed(0)} Bs</b>
                </span>
            </div>
        </>)
    } 
    const AcordionHeaderBody = ()=>{
        return (<div className="accordion-titulo">
            <span className="accordion-titulo-carton">CARTON</span>
            <span className="accordion-titulo-nombre">NOMBRE GANADOR</span>
            <span className="accordion-titulo-premio">PREMIO</span>
        </div>)
    }
    const AcordionHeaderTab = ({item,title}) =>{
        return (<div className="tab-acordion" style={{}}>
            <span>{title}</span>
            <span>{item.length} { item.length == 1 ? "GANADOR":"GANADORES"}</span>
        </div>)
    }
    const AcordionGanador = ({item,premio,inicial})=>{
        return item?.map((ganador,index)=>{
            var contenidoParentesis = ganador.match(/\(([^)]+)\)/);
            var winner = contenidoParentesis ? contenidoParentesis[1] : null;            
            var match = null;
            switch(inicial) {
                case "L1": match = ganador.match(/L. Horizontal Nro: (\d{4})/);break;
                case "L2": match = ganador.match(/L. Vertical Nro: (\d{4})/);break;
                case "E": match = ganador.match(/ESQUINAS Nro: (\d{4})/);break;
                case "D": match = ganador.match(/DIAGONAL Nro: (\d{4})/);break;
                case "DI": match = ganador.match(/DIAGONAL INV Nro: (\d{4})/);break;
                case "C": match = ganador.match(/CRUZ Nro: (\d{4})/);break;
                case "LL": match = ganador.match(/LLENO  Nro: (\d{4})/);break;

                default : match = ganador.match(/Linea Nro: (\d{4})/);break;
            }
            
            var numeroCarton = match ? match[1] : null;
            if (!numeroCarton) {
                match = ganador.match(/(\d{4})/);
                numeroCarton = match ? match[1] : null;
            }
            let numeral = numeroCarton;
            let premioActual = premio / item.length
            return (
                <div className="accordion-contenido" key={'ganadores-acordion-' + index}>
                    <span className="accordion-contenido-carton">#{numeral}</span>
                    <span className="accordion-contenido-nombre">{winner}</span>
                    <span className="accordion-contenido-premio">{premioActual.toFixed(0)} Bs</span>
                </div>
            )
        })
    }
    const TablaGanadores= () =>{
        const rondasList = ["RONDA 1","RONDA 2","RONDA 3","RONDA 4"]
        const rondasActivas = rondasList.filter((a,i)=> i < rondas)
        return( 
        <div className="div-tabla-detalles ganadores z-[99] mb-10"> 
            <div  style={{gap:1,display:"flex",flexDirection:"column",alignItems:"center"}}>
                <span className="ganador-tittle" style={{textAlign:"center"}}>
                    <b>LISTA DE GANADORES</b>
                </span>                
                <div className="w-full" style={{minHeight:"290px",display:"block"}}>
                    <Tabs className='w-full'>
                        <TabList>
                            {rondasActivas.map((ronda,index)=>{
                                return (
                                <Tab key={'ganadores-head-' + index}>
                                    <span className="text-sm text-white" >{ronda}</span> 
                                </Tab>
                                )
                            })}
                        </TabList>
                        {rondasActivas.map((ronda,index)=>{
                            let Gana = Ganadores_1
                            switch(index){
                                case 0: Gana = Ganadores_1 ; break ;
                                case 1: Gana = Ganadores_2 ; break ;
                                case 2: Gana = Ganadores_3 ; break ;
                                default: break ;
                            } 
                            const ganadoresLinealH = Gana?.filter((a) => a.includes("L. Horizontal Nro:") )
                            const ganadoresLinealV = Gana?.filter((a) => a.includes("L. Vertical Nro:") )
                            const ganadoresEsquinas = Gana?.filter((a) => a.includes("ESQUINAS Nro:") )
                            const ganadoresDiagonal = Gana?.filter((a) => a.includes("DIAGONAL Nro:") )
                            const ganadoresDiagonalInv = Gana?.filter((a) => a.includes("DIAGONAL INV Nro:") )
                            const ganadoresCruz = Gana?.filter((a) => a.includes("CRUZ Nro:") )
                            const ganadoresLleno = Gana?.filter((a) => a.includes("LLENO  Nro:") )
                            return (
                            <TabPanel key={'ganadores-tabla-' + index}> 
                                <div className="" style={{maxHeight:"250px",overflowY:"visible",overflowX:"hidden"}}>
                                    <Accordion defaultActiveKey={"0"}>
                                       {/* <Accordion.Item eventKey="0"  >
                                            <Accordion.Header >
                                                <AcordionHeaderTab title="LINEA HORIZONTAL" item={ganadoresLinealH}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresLinealH} premio={PremiosLittle}  inicial="L1" />
                                            </Accordion.Body>
                                        </Accordion.Item>}
                                        {<Accordion.Item eventKey="1"  >
                                            <Accordion.Header >
                                                <AcordionHeaderTab title="LINEA VERTICAL" item={ganadoresLinealV}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresLinealV} premio={PremiosLittle}  inicial="L2" />
                                            </Accordion.Body>
                                        </Accordion.Item>*/}
                                        <Accordion.Item eventKey="2"  >
                                            <Accordion.Header >
                                                <AcordionHeaderTab title="DIAGONAL" item={ganadoresDiagonal}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresDiagonal} premio={PremiosLittle} inicial="D" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                       {/* <Accordion.Item eventKey="3"  >
                                            <Accordion.Header >
                                                <AcordionHeaderTab title="DIAGONAL INV." item={ganadoresDiagonalInv}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresDiagonalInv} premio={PremiosLittle}  inicial="DI" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        */}
                                        <Accordion.Item eventKey="4"  >
                                            <Accordion.Header >
                                                <AcordionHeaderTab title="CRUZ GRANDE" item={ganadoresCruz}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresCruz} premio={PremiosLittle} inicial="C" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="5"  >
                                            <Accordion.Header >
                                                <AcordionHeaderTab title="4 ESQUINAS" item={ganadoresEsquinas}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresEsquinas} premio={PremiosLittle} inicial="E" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="6"  >
                                            <Accordion.Header >
                                                <AcordionHeaderTab title="CARTON LLENO" item={ganadoresLleno}/>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <AcordionHeaderBody />
                                                <AcordionGanador item={ganadoresLleno} premio={PremiosBig}  inicial="LL" />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </TabPanel>
                            )
                        })}
                        
                    </Tabs>
                </div>
            </div>
        </div> 
        )
    }
  
    const handleChange = (value) => {
       const Sanitize = value.replace(/[^0-9]/g, '');
        console.log(value);
        
         setInputValue(Sanitize); // Actualizar el estado local
         setSerialToAdd(Sanitize);
    }

    const UpdateCartonesLocal = (value = []) =>{
        localStorage.setItem('cartonesSaved',value)
        setCartonesLocal(value)
    }
    const CartonAdd = () =>{
        return (<>
            <div className="w-full flex gap-x-[20px] bg-[#55bb99] 
                p-2 justify-center h-[50px] items-center" 
                
                >
                {!isMobile && <span>AGREGAR CARTON</span>}
                { !isMobile && 
                 
                <div id="clickable"   data-tooltip-place="top" className="bg-blue-600 w-[30px] h-[30px] rounded-full flex justify-center items-center hover:w-[35px] hover:h-[35px] hover:text-xl">
                    <FontAwesomeIcon icon={faInfo} />   
                    <Tooltip anchorSelect="#clickable" className="z-[100000] bg-black flex flex-col justify-start items-center absolute" >
                        <span>Este es el numero</span> 
                        <span>que debes ingresar</span>
                        <img src={"/images/carton-explicacion-2.png"} height={200}  width={260}  alt="sad"/>   
                    </Tooltip>
                </div> 
                }
                 
                 

                 <input 
                     className="p-2 text-[black] w-48 lg:w-64"
                     ref={input_add}
                     type="number"  
                     placeholder="Serial de tu carton" 
                     value={inputValue} // Convertir a input controlado
                     onChange={(e)=>handleChange(e.target.value)} 
                     autoFocus={true}
                /> 
                 
                <button className="bg-[#7f2e93] p-2 rounded-[5px]"
                    onClick={()=>{                   
                    let validar = false;                    
                    Cartones?.map((e)=>{
                        const serial = (e.serial).replace(sorteoActual,'')
                        if( parseInt(serial) == parseInt(serialToAdd)){
                            UpdateCartonesLocal([...CartonesLocal.filter((a)=> parseInt(a) != parseInt(serialToAdd)),serialToAdd])                              
                            validar=true;
                        }                        
                    })
                    if(!validar){
                        alert('el carton no es valido para este sorteo o no fue vendido aun');
                    } else {
                        input_add.current.value = ''
                    }
                }}
                >AGREGAR</button>
            </div>
        </>)
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);
      
    useEffect( () => {    
        if(CARTONSAVED != undefined && CARTONSAVED != null){
            setCartonesLocal(CARTONSAVED.split(","))
        }
        GetGeneralConfigs()
        GetCartones()       

        const interval = setInterval(()=>{
            UpdateBolasSorteo()
        }, 5000);
  
        return () => {
            clearInterval(interval)
        };

    }, []);

    useEffect(() => { 
        const timeout = setTimeout(()=>{
            GetBolasSorteo()
        }, BallDelay);
        return  clearTimeout(timeout)
    }, [BolasSorteo]);

    useEffect( () => {    
        GetBolasSorteo()
    }, [BolasSorteo]);

    useEffect( () => {      
       GetGanadores()           
        const interval = setInterval(()=>{ 
            GetGanadores()
          }, 60000);
        return () => clearInterval(interval);
    }, []);  
  
    useEffect( () => { 
        const timeout = setTimeout(() => {
            updateTotalRepartir()
          }, 60000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect( () => {
        updateTotalRepartir() 
    }, [vendidos,PremiosBig,PremiosLittle,rondas]);
    return (
        <Dashboard> 
            <div className="pages-sorteo bg-[#1a1a1a] w-full min-h-screen text-white">
                <div className="mx-auto w-full mt-1 px-4 sm:px-6 lg:px-8">
                    <div className="flex bg-gray-900 flex-col mt-[50px] w-full fixed left-0 top-0 z-50">  
                        <div className="flex justify-between items-center p-2 bg-gray-900 w-full">
                            <span className="flex justify-between text-sm w-full">
                                <b>{`${dia} ${fecha}`}</b>
                                <b className="uppercase">{sorteoActual}</b>
                            </span>
                            {!isMobile && (
                            <button onClick={expandbeHandler} className="text-white hover:text-purple-400">
                                <FontAwesomeIcon icon={youtubeExpand ? faMinimize : faExpand} />
                            </button>
                            )}
                        </div>
                        <Youtube link={link} expand={youtubeExpand} />  
                        <Tablero Bolas={BolasList} />
                        <CartonAdd />  
                    </div> 
                    <div className="flex flex-row flex-wrap justify-center gap-4 item-center lg:justify-start mt-[360px] mb-10 " >
                        <Carton
                        Bolas={BolasList}
                        CartonesSaved={CartonesLocal}
                        SaveCarton={UpdateCartonesLocal}
                        Sorteo={sorteoActual}
                        Cartones={Cartones}
                        serialToAdd={serialToAdd}
                        />
                    </div>   
                    <TablaGanadores /> 
                </div>
            </div>
        </Dashboard>
      
    );
  }
  export default ViewSort 