/* eslint-disable */
import React from "react"; 
import '../../App.css';
import './Tablero.css'; 
function Tablero({ Bolas }) {
    const Salieron = () => {
      return   Bolas
        .slice(0)
        .reverse()
        .map((e, i) => (
          <div
            className={`bolita ${i === 0 ? 'ultima' : ''}`}
            data-number={e}
            key={`salieron-tabla-${i}`}>
            {e}
          </div>
        )) 
    };
  
    return (
      <div className="comp-tablero tablero-container">
        <div className="bolitas-wrapper">
          <Salieron />
        </div>
      </div>
    );

  }
  
  export default Tablero;