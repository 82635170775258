/* eslint-disable */
import React, { useEffect,  useState } from "react"; 
import { Footer } from '../../components';
import './home.css';  
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { isMobile } from 'react-device-detect'; 
import 'react-tabs/style/react-tabs.css';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';  
 import { GetAllConfigs } from "../../helpers/helpers";
import AuthContext from "../../context/AuthContext";
import Dashboard from "../dashboard";
import GloboPremio from "../../components/GloboPremio/GloboPremio";
import CallToActionButton from "../../components/CallToActionButton/CallToActionButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion, faRefresh } from "@fortawesome/free-solid-svg-icons";


function Home() {
    const context = React.useContext(AuthContext);
    
    const location = useLocation();
    const [configs, setConfigs] = useState([]);
      
    const [vendidos,setVendidos] = useState(0);
    const [TotalRepartir, setTotalRepartir] = useState(0.00)
    const [BaseTotalRepartir, setBaseTotalRepartir] = useState(1.00)
    const [sorteoActual, setSorteoActual] = useState(); 
    const [PremiosLittle, setPremiosLittle] = useState(0.00)
    const [PremiosBig, setPremiosBig] = useState(0.00) 
  
    const [publicidad, setPublicidad] = useState(0.00)
    const [fecha, setFecha] = useState();
    const [dia, setDia] = useState();
    const [hora, setHora] = useState();
    const [rondas, setRondas] = useState(1); 
    const [precioCarton, setPrecioCarton] = useState(60); 
    const [totalPremios, setTotalPremios] = useState(5);

    const [porcentajePromotor, setPorcentajePromotor] = useState(0.10)
    const [porcentajeAdmin, setPorcentajeAdmin] = useState(0.05)
    const [porcentajeOwner, setPorcentajeOwner] = useState(0.1)
    const [bonusPromotor, setBonusPromotor] = useState(0.05) 
    const [isLoading, setIsLoading] = useState(true)
    const [ShowAds, setShowAds] = useState()
    const { isLogued } = context;
     
    const GetGeneralConfigs = async () =>{
        setIsLoading(true)   
        const allConfigs = await GetAllConfigs() 
        setConfigs( allConfigs ) 
        setIsLoading(false)   
    } 
    const getUniqueConfig = (tipo = '') => configs.find(item => item.tipo === tipo )?.valor || '' 

    const updateTotalRepartir = async() =>{  
        const premioS = BaseTotalRepartir / ( totalPremios  * rondas)
        setPremiosLittle( premioS ) 
        setPremiosBig(( premioS ) * 2 ) 
        setTotalRepartir(BaseTotalRepartir)  
    }
   
     
    const Header_1 = ( ) =>{
       
        
        
        return (<>
            <div  className="sm:mt-[50px] lg:mt-0 flex flex-col h-auto p-[10px]"
                style={ { background:"url('images/bg_head_1.png')", backgroundSize: "cover"}}
                >
                <div  className="flex flex-col lg:flex-row w-auto gap-[20px] justify-between" > 
                    <div className="h-auto w-full flex items-center justify-center gap-[20px] bg-[#8880]">  
                        <div className="bg-[#fff] h-[auto] text-center w-10/12 lg:w-[auto] flex items-center justify-center p-[2px] px-[20px] rounded-[10px]" >
                            <span className="text-[14px] text-center uppercase" >
                                <b>Sorteo Nº: </b>
                                <b style={{color:"red"}}>{sorteoActual?.slice(0, 5)}</b>
                                <b>{sorteoActual?.slice(5, sorteoActual.length)}</b>
                            </span>
                        </div>
                    </div>  

                    <div className="flex md:flex-row   p-0    justify-between lg:justify-start lg:flex-col pl-[20px] pr-[20px] gap-x-[30px] w-[auto] lg:gap-[10px]">
                    
                        
                        <div  className="flex flex-col lg:flex-row bg-[#00000000] h-[100px] lg:h-[70px] w-[100px] lg:w-[375px] text-[white]  ml-[25px] mt-[10px] lg:mt-[25px]" >
                            <div 
                                className="flex flex-col z-[10] items-center -mr-[5px]  p-[5px] bg-[#960084] w-[100px] lg:w-[50%] justify-start h-[30px] lg:h-[60px]"
                                style={ { transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077"}}>
                                <span className="text-[12px] lg:text-[20px] absolute -mt-[15px] font-bold"
                                    style={{transform:"skewX(30deg)"}}>
                                    CON TAN SOLO
                                </span>
                                <span className="text-[22px]  -mt-[5px] lg:mt-[0px] lg:text-[44px] font-bold text-[#ffcc00]" 
                                    style={{transform:"skewX(30deg)"}}>
                                    <b className="ml-[10px]"  >{precioCarton}</b>
                                    <b className="text-[20px] lg:text-[32px]">
                                        Bs
                                    </b> 
                                </span>
                            </div>
                            <div 
                                className="flex flex-col z-[9] items-center ml-[5px] mt-2 lg:mt-0 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                <span className='text-[14px] lg:text-[18px] absolute -mt-[20px] font-bold' 
                                    style={{transform:"skewX(30deg)"}}>
                                    PARTICIPAS EN
                                </span>
                                <span className="text-[18px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                    <b>{rondas} RONDA{rondas>1?'S':''}</b>
                                </span>
                            </div>
                        </div>
                         
                        <div className="flex mr-[10px] p-[10px]    flex-col justify-center items-center  bg-[#ff392a] h-[100px] w-6/12  rounded-[20px] -mt-[5px] gap-y-[10px] " > 

                            <div className="flex justify-evently  mt-[30px] w-full" >
                                <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/envivo_icon.png" alt=""  />
                                <img className="object-contain h-[15px]" src="https://bingove.com/images/icons/youtube_icon_002.png" alt="" />
                            </div>
                            <span className="text-white text-[22px] font-bold w-full">@bingo_ve</span>
                            <div  className="flex flex-row bg-[#00000000] h-[50px] lg:h-[70px] w-full lg:w-[375px] text-[white]" >
                                <div  className="flex flex-col z-[9] items-center pl-[10px] pr-[10px] bg-[#960084] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                    
                                    <span className='text-[12px] lg:text-[20px] absolute -mt-[10px] font-bold' 
                                        style={{transform:"skewX(30deg)"}}>
                                        HORARIO
                                    </span>
                                    <span className="text-[20px] lg:text-[32px] mt-[2px]" style={{transform:"skewX(30deg)"}}>
                                            <b >{hora} </b>      
                                    </span>
                                </div>
                                <div  className="flex flex-col z-[9] items-center mt-4 -ml-8 p-[10px] bg-[#8a35a5] w-[120px] lg:w-[50%]  justify-start h-[30px] lg:h-[60px] "
                                    style={{ transform:"skewX(-30deg) rotate(-5deg)", boxShadow:"5px 5px 10px 5px #00000077" }}>
                                    <span className='text-[12px] lg:text-[20px] absolute -mt-[20px] font-bold' 
                                        style={{transform:"skewX(30deg)"}}>
                                        {dia}
                                    </span>
                                    <span className="text-[20px] lg:text-[32px] -mt-[5px] lg:mt-[0px]" style={{transform:"skewX(30deg)"}}>
                                            <b >{fecha} </b>      
                                    </span>
                                </div>
                            </div>
                        </div> 
                    </div>
                    <div className="bg-[#55555500] h-[100%] w-full lg:w-[45%] flex flex-col items-center"
                        >
                        
                        <div className="bg-[#9999900] h-[130px] max-[480px]:mt-[0px]  mt-[100px]  lg:h-[100px] w-[100%] flex items-end">
                            <div  className="h-[120px] lg:h-[180px] flex items-center w-[100%] justify-center rounded-[90px]"
                                style={
                                {
                                    background:"linear-gradient(0deg,#00000000 0%,#7f2e93 20%,#7f2e93 100%)"
                                }}>
                                <span className="text-center flex flex-col gap-0">
                                    <b className="text-[white] text-[24px] lg:text-[40px]">Total a repartir</b>
                                    <b className="text-[#ffcc00] text-[50px] lg:text-[80px] -mt-[20px]">{TotalRepartir?.toFixed(0)} Bs</b>
                                    <b className="text-[white] text-[12px] lg:text-[12px] -mt-[14px]">El total a repartir aumenta con las ventas!</b>
                                </span>
                            </div>
                        </div>
                    </div> 
                </div>
                <div className="flex flex-col lg:flex-row h-[100%] w-[100%]  justify-between items-center"> 
                    <div className="flex max-sm:mt-5 flex-col gap-[35px] w-full ">
                        <div className="flex flex-row gap-[5px] w-full">
                            <GloboPremio size="small" name="Esquinas" premio={PremiosLittle } solid/>
                            <GloboPremio size="small" name="Diagonal" premio={PremiosLittle } solid/> 
                            <GloboPremio size="small" name="Cruz" premio={PremiosLittle } solid/> 
                        </div> 
                        <GloboPremio size="medium" name="Carton Lleno" premio={PremiosBig } solid/>
                    </div>
                    
                </div>
            </div>
        </>)
    } 

    const Ads = ( ) =>{
        
        
        return (<>
                <div>
                    
                     <Link to={isLogued ? '/Referals' : '/login'} className="main-menu-link no-underline" >                             
                        <img src="images/referidos.png"/>
                        {/* <video src="Videos/Referidos.mp4" typeof="video/mp4" className="h-screnn" autoPlay muted loop></video> */}
                     </Link>            
                 </div>
                </>)}
   
   const CarrouselJs = () =>{  
    
  
    
    return ( 
    <Carousel
        autoPlay={false}
        infiniteLoop={true}
        interval={3000}
        showThumbs={false}
        showArrows={false}
        dynamicHeight={false}
        stopOnHover={false}
        swipeable={true}
        className="  h-[auto] mt-10 lg:mt-0 "
    >
        <Header_1 />
        <Ads />
    </Carousel> 
        )
    }
    
    const Flotantes = () =>{
        return(
            <div className="position fixed text-white bottom-20 right-5 z-10">

                <a href="/sorteo">
                    <img className="h-[8vh]" src="images/logo_youtube.png" alt="Youtube"/>
                    
                </a>
                {!isLogued && (

                    <a href="https://youtu.be/Htopog2KrIA?si=yGaSbcghMLfKZCSu">
                    <img className="h-[8vh] bg-white rounded-3xl mt-2" src="images/duda.png" alt="Youtube"/>
                </a>
                )}
                
                

            </div>
        )
    }
    
    useEffect( () => {
        GetGeneralConfigs();
        const timeout = setTimeout(() => updateTotalRepartir(), 10000);
        return () => clearTimeout(timeout);
    }, []);

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);

    useEffect( () => {
        // obtener datos de las congifuraciones iniciales
        setSorteoActual( getUniqueConfig('sorteo_actual') )
        setVendidos( getUniqueConfig('cartones_vendidos') )
        setPremiosLittle( parseFloat(getUniqueConfig('premio_base_small')) )
        setPremiosBig( parseFloat(getUniqueConfig('premio_base_big')) )
        setTotalRepartir( parseFloat(getUniqueConfig('premio_base_total')) )
        setBaseTotalRepartir( parseFloat(getUniqueConfig('premio_base_total')) )
        setPublicidad( parseFloat(getUniqueConfig('precio_publicidad')) )
        setFecha( getUniqueConfig('sorteo_fecha') )
        setDia( getUniqueConfig('sorteo_dia') )
        setHora( getUniqueConfig('sorteo_hora') )
        setRondas( parseInt(getUniqueConfig('sorteo_rondas')) )
        setPrecioCarton( parseFloat(getUniqueConfig('cartones_precio')) )    
        setPorcentajePromotor( getUniqueConfig('porcentaje_promotor') )
        setPorcentajeAdmin( getUniqueConfig('porcentaje_admin') )
        setPorcentajeOwner( getUniqueConfig('porcentaje_owner') )
        setBonusPromotor( getUniqueConfig('bonus_promotor') ) 
        setTotalPremios( getUniqueConfig('cantidad_premios') )

    }, [configs]);

    useEffect( () => {
        updateTotalRepartir() 
     }, [ vendidos, PremiosBig, PremiosLittle, rondas ]);




 
    return ( 
        <Dashboard> 
            {isLoading && 
            <div className="absolute top-0 left-0 w-full h-screen bg-[#000e] z-[100] flex items-center justify-center">
                <FontAwesomeIcon icon={faRefresh} className="text-[white] text-[50px] animate-spin" />
            </div>}
            <div className="pages-home flex flex-col gap-[1] pb-[0px]" > 
                <CarrouselJs /> 
                <CallToActionButton 
                    url={ isLogued ? '/cardlist' : '/login'} 
                    titulo='COMPRA TU CARTON AQUI'
                />     
                <Flotantes/>
                <Footer /> 
            </div>
        </Dashboard> 
    );
  }
  export default Home;

 //https://api.whatsapp.com/send/?text=Hola+%2C+Quisiera+saber+mas+de+BingoVe.&type=phone_number&phone=584124431815