/* eslint-disable */
import React from "react"


const GloboPremio = ({name = "Esquinas", premio = 0,size = "40",solid}) =>{
        
    const general = 'w-full flex items-center justify-center rounded-[90px]'
    const BgGradient = `bg-[linear-gradient(0deg,#7f2e93 0%,#7f2e93 20%,#00000000 100%)]`
    const BgSolid = ` bg-[#7f2e93aa] lg:bg-[#7f2e93] `
    const Small = {
        general:'h-[40px] lg:h-[50px] text-[15px] ' + general,
        title:'text-[white] text-[12px] lg:text-[15px] mt-2 lg:-mt-0',
        premio:`text-white lg:text-[#ffcc00] text-[20px] lg:text-[30px] lg:-mt-2`,
        caja : "text-center flex flex-col lg:pt-[5px] lg:pb-[5px]  lg:-mt-2 h-[60px] lg:h-[70px] "
    }
    const Medium = {
        general:'w-[70%] lg:w-[50%] h-[70px] lg:h-[80px] text-[25px] -mt-5 lg:-mt-0 ' + general,
        title:'text-[white]  text-[20px] lg:text-[25px]',
        premio:'text-white lg:text-[#ffcc00] text-[40px]  lg:text-[60px] -mt-4',
        caja : "text-center flex flex-col pt-[5px] pb-[5px] -mt-2 lg:-mt-6 lg:gap-2"
    }
    const Large = {
        general:'h-[180px] text-[90px] ' + general,
        title:'text-[white] text-[90px]',
        premio:'text-[#ffcc00] text-[180px] ',
        caja : "text-center flex flex-col pt-[5px] pb-[5px] "
    }
    let Css_01 = general
    switch(size){
        case "small" : Css_01 = Small; break ;
        case "medium" : Css_01 = Medium; break ;
        case "large" : Css_01 = Large; break ;
        default : break ;
    }

    const bg_gradient = solid ? BgSolid : BgGradient
    return (<>
        <div className={Css_01?.general + bg_gradient}>
            <span className={Css_01?.caja} >
                <b className={Css_01?.title}>{name}</b>
                <b className={Css_01?.premio} style={{}}>
                      {premio?.toFixed(0)} bs 
                </b>
            </span>
        </div>
    </>)
} 

export default GloboPremio