/* eslint-disable */
import React, { useState,useEffect, useRef } from 'react'
import { useFormikContext, Field } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import axios from 'axios'
const baseURL = process.env.REACT_APP_URL_API_V2
function UserInfo ({isValid,dirty,onConfirmData,onCancel}) {
  const { errors, touched } = useFormikContext()
  const [showPassword, setShowPassword] = useState(false)
  const [username, setUsername] = useState(false)
  const [showPassword2, setShowPassword2] = useState(false)
  const [isValidForm, setIsValidForm] = useState(isValid)
  const [isDirty, setIsDirty] = useState(dirty)
  const submitRef = useRef(null)
  const togglePassword = (type) => {
    if (type === 'password') setShowPassword(!showPassword)
    else setShowPassword2(!showPassword2)
  }
  const handleUserValue = (value) =>{
    const removeAccents = value.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
    setUsername(removeAccents) 
    console.log(username);
  }
  const handleConfirmData = async(username) => { 
    const response = await axios.get(baseURL + `/users/verifyUserName/${username}`)
    if(response.data.status == 1 ){ 
      if(onConfirmData){
        submitRef.current.click()
        onConfirmData()
      }
    } else {
      alert(response.data.status_message)
    }  
  }
  const handleCancel= () => {
    if(onCancel){
      onCancel()
    }
  }
  
  useEffect(()=>{
    setIsValidForm(isValid)
    setIsDirty(dirty)
  },[dirty,isValid])

  return (
    <>
      <div className='my-3'>
        <label className="block text-sm font-medium text-gray-700">
          Nombre de Usuario BingoVE
        </label>
        <Field
          validate={handleUserValue}
          name="username"
          type="text"
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]"
          placeholder="Nombre de usuario sin espacios"
        />
        {errors.username && touched.username && (
          <p className="text-red-500 text-sm mt-1">{errors.username}</p>
        )}
      </div>

      <div className='my-3'>
        <label className="block text-sm font-medium text-gray-700">
          Contraseña (Obligatorio)
        </label>
        <div className="relative">
          <Field
            name="password"
            type={showPassword ? 'text' : 'password'}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]"
            placeholder="Ingrese clave alfanumérica"
          />
          <button
            type="button"
            className="absolute mt-2 -ml-8 p-1 text-gray-600"
            onClick={() => togglePassword('password')}
          >
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </button>
        </div>
        {errors.password && touched.password && (
          <p className="text-red-500 text-sm mt-1">{errors.password}</p>
        )}
      </div>

      <div className='my-3'>
        <label className="block text-sm font-medium text-gray-700">
          Confirmar Contraseña
        </label>
        <div className="relative">
          <Field
            name="confirmPassword"
            type={showPassword2 ? 'text' : 'password'}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring focus:ring-[purple]"
            placeholder="Repita clave alfanumérica"
          />
          <button
            type="button"
            className="absolute mt-2 -ml-8 p-1 text-gray-600"
            onClick={() => togglePassword('confirmPassword')}
          >
            <FontAwesomeIcon icon={showPassword2 ? faEye : faEyeSlash} />
          </button>
        </div>
        {errors.confirmPassword && touched.confirmPassword && (
          <p className="text-red-500 text-sm mt-1">{errors.confirmPassword}</p>
        )}
      </div>

      <div className='flex justify-between my-3'> 
        
        <button
          type="button"
          className="rounded-lg border-2 w-full mr-4 p-1 font-[600] uppercase bg-[purple] text-white hover:bg-purple-950 disabled:bg-gray-500"
          onClick={handleCancel}
        >
          Anterior
        </button>
        <button
          ref={submitRef}
          type="submit"
          className="hidden"
          disabled={!isValidForm || !isDirty} 
        > 
        </button>
        <button
          type="button"
          className="rounded-lg border-2 py-1 w-full font-[600] uppercase ml-4 bg-[purple] text-white hover:bg-purple-950 disabled:bg-gray-500"
          disabled={!isValidForm || !isDirty}
          onClick={()=> isValidForm && isDirty ? handleConfirmData(username) : ()=>{}}
        >
          Siguiente
        </button>
      </div> 
    </>
  )
}

export default UserInfo
