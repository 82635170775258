/* eslint-disable */
import { useState, useEffect, useContext } from 'react'
import api from '../../services/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash, faX } from '@fortawesome/free-solid-svg-icons'
import CartonInvidual from '../../components/carton/CartonIndividul'
import AuthContext from '../../context/AuthContext'
import { Dashboard } from '..'


function UserHistory() {
  const context = useContext(AuthContext)
  const [data, setData] = useState([])
  const [eyeActive, setEyeActive] = useState(null)
  const [cartonSerial, setCartonSerial] = useState([])
  const [cartones, setCartones] = useState([])
  const [modalCarton, setModalCarton] = useState(false)
  const [CartonesLocal, setCartonesLocal] = useState([])
  const [historyOrden, setHistoryOrden] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [textLoading, setTextLoading] = useState('')

  //todo para  configuraciones
  const [configs, setConfigs] = useState([]);
  const [link, setLink] = useState();
  const [vendidos, setVendidos] = useState();
  const [TotalRepartir, setTotalRepartir] = useState(0)
  const [sorteoActual, setSorteoActual] = useState();
  const [historial, setHistorial] = useState(false);
  const [PremiosLittle, setPremiosLittle] = useState(0)
  const [PremiosBig, setPremiosBig] = useState(0)
  const [videoHome1, setVideoHome1] = useState({})
  const [videoHome2, setVideoHome2] = useState({})
  const [videoUltimo, setVideoUltimo] = useState({})
  const [publicidad, setPublicidad] = useState(0)
  const [fecha, setFecha] = useState();
  const [dia, setDia] = useState();
  const [hora, setHora] = useState();
  const [rondas, setRondas] = useState(1);
  const [precioCarton, setPrecioCarton] = useState(1);
  const [ventasStatus, setVentasStatus] = useState(false);
  const [promotorStatus, setPromotorStatus] = useState(false);
  const [promotorEmpleo, setPromotorEmpleo] = useState(false);
  const [adminStatus, setAdminStatus] = useState(false);
  const [listMode, setListMode] = useState(false);
  const [infoCart, setInfoCart] = useState([])
  const [serialToAdd, setSerialToAdd] = useState();
  const [Orden, setOrden] = useState("asc")

  const clientId = context.user.clientId

  const obtenerOrdenes = async () => {
    await api.get(`obtenerPedidos/${clientId}`)
      .then((response) => {
        if (response?.data?.content.length > 0) {
          const history = response?.data?.content || []

          try {
            const historyParsed = history.map(item=>{
              return {
                ...item,
                carrito: JSON.parse(item.carrito)
              }
            }) 
            setHistoryOrden(historyParsed.sort((a,b)=>b.sorteo.localeCompare(a.sorteo)))
            
          } catch (error) {
            console.log("error al parsear el carrito")
          }


        }
      })
      .catch((err) => {
        console.log("Error Al optener las ordenes", errr)
      })
  }

  useEffect(() => {
    obtenerOrdenes()
  }, [])

  // Api en espera
  const hiistoryCarton = [
    {
      "orden": "s001",
      "sorteo": "s1",
      "fecha": "10/10/2024",
      "cartones": {
        "s0063100720240001": "ganador",
        "s0062300620240049": "perdedor",
        "1982": "perdedor",
        "1162": "ganador",
      }
    },
    {
      "orden": "s090",
      "sorteo": "s1",
      "fecha": "10/10/2024",
      "cartones": {
        "77135": "ganador",
        "1807": "perdedor",
        "1008": "perdedor",
        "1012": "ganador",
      }
    },
    {
      "orden": "s024",
      "sorteo": "s1",
      "fecha": "10/10/2024",
      "cartones": {
        "1010": "ganador",
        "1011": "perdedor",
        "1012": "perdedor",
      }
    },
    {
      "orden": "s002",
      "sorteo": "s2",
      "fecha": "11/11/2024",
      "cartones": {
        "1013": "ganador",
        "1014": "perdedor",
        "1015": "ganador",
        "1016": "perdedor",
      }
    },
    {
      "orden": "s003",
      "sorteo": "s3",
      "fecha": "12/12/2024",
      "cartones": {
        "1017": "perdedor",
        "1022": "perdedor",
        "1029": "perdedor",
        "1032": "ganador",
      }
    },
    {
      "orden": "s004",
      "sorteo": "s4",
      "fecha": "13/01/2025",
      "cartones": {
        "1010": "ganador",
        "1011": "ganador",
        "1012": "perdedor",
        "1012": "ganador",
      }
    },

  ]
  const GetAllConfigs = async () => {
    await api.get('/read/configs')
      .then((response) => {
        if (response?.data?.content.length > 0) {
          const TempConfigs = response?.data?.content
          setConfigs(TempConfigs);
          TempConfigs?.map((item) => {
            const { id, tipo, valor, extra } = item
            switch (tipo) {
              case 'sorteo_actual':
                setSorteoActual(valor)
                break;
              case 'sorteo_youtube':
                setLink(valor)
                break;
              case 'sorteo_fecha':
                setFecha(valor)
                break;
              case 'sorteo_hora':
                setHora(valor)
                break;
              case 'sorteo_dia':
                setDia(valor)
                break;
              case 'ventas_status':
                setVentasStatus(valor == '1')
                break;
              case 'promotores_status':
                setPromotorStatus(valor == '1')
                break;
              case 'administradores_status':
                setAdminStatus(valor == '1')
                break;
              case 'sorteo_rondas':
                setRondas(valor)
                break;
              case 'premio_base_small':
                setPremiosLittle(parseFloat(valor))
                break;
              case 'premio_base_big':
                setPremiosBig(parseFloat(valor))
                break;
              case 'premio_base_total':
                setTotalRepartir(parseFloat(valor))
                break;
              case 'cartones_precio':
                setPrecioCarton(parseFloat(valor));
                break;
              case 'promotores_empleo':
                setPromotorEmpleo(valor == '1')
                break;
              case 'video_home_1':
                setVideoHome1(item)
                break;
              case 'video_home_2':
                setVideoHome2(item)
                break;
              case 'video_ultimo_sorteo':
                setVideoUltimo(item)
                break;
              case 'cartones_vendidos':
                setVendidos(parseInt(valor))
                break;
              case 'precio_publicidad':
                setPublicidad(parseInt(valor))
                break;
              case 'home_historial':
                setHistorial(valor == '1')
                break;
              //cartones_vendidos
              default: break;
            }

          })
        }
      })
      .catch((err) => {
        console.error("ops! ocorreu um erro" + err);
      });
  }
  const CartonLoader = () => {
    return (
      <div className="flex bg-[#0007] w-[100vw] h-[100vh] top-0 left-0 right-0 justify-center items-center fixed  z-[100]">
        <div className="flex justify-center loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-32 w-32">
        </div>
        <span className='text-center text-white fixed font-bold'>
          {textLoading != '' ? textLoading : 'CARGANDO'}
        </span>
      </div>
    )
  }


  const GetCartoneIndividual = async () => {
    setIsLoading(true);
    try {
      const response = await api.get(`obtenerCarton/${cartonSerial}`);
      if (response?.data?.content?.length > 0) {
        const TempCartones = response.data.content

        const TempLista = TempCartones.map((item) => {
          return {
            ...item,
            numero: item.numero_carton
          }
        }
        ); 
        setCartones(TempLista);
        setCartonesLocal(TempLista);
      }
    } catch (err) {
      console.error('Error al cargar los carton:', err);
    }
    setIsLoading(false);
  }

  const handleOpenEye = (orden) => {
    setEyeActive((prevOrder) => (prevOrder === orden ? null : orden));
  }

  const handlerModalCarton = () => {
    setModalCarton(false);
    setCartonSerial([])
  }

  
  //console.log(cartonSerial)

  useEffect(() => {
    setData(context.user)
  }, [context])

  useEffect(() => {
    GetAllConfigs()
  }, []);

  useEffect(() => {
    GetCartoneIndividual()
  }, [cartonSerial])





  const renderHistory = () => {
    const groupedHistory = historyOrden.reduce((acc, item) => {
      if (!acc[item.sorteo]) acc[item.sorteo] = [];
      acc[item.sorteo].push(item);
      return acc;
    }, {});


    const handleOpenEyeCarton = (carton) => {
      setCartonSerial(carton);
      setModalCarton(true);
    }




    return (
      <div className='w-full '>
        {Object.entries(groupedHistory).map(([sorteo, ordenes]) => (
          <div key={sorteo} className="mb-4">
            <h2 className=" my-4  pl-4 text-xl font-bold text-gray-200"> Sorteo: {sorteo}  { sorteo == sorteoActual ? <span className='text-green-400'> - (En Curso)</span> : <span className='text-red-400'> - (Caducado)</span>} </h2>
            <div className="flex w-full flex-col items-center justify-center gap-2">

              {ordenes.sort((a,b) => b.id - a.id ).map((orden) => (
                <div className='flex flex-col w-full md:w-1/2 bg-white rounded-lg justify-center shadow-lg'>
                  <div
                    key={orden.orden}
                    className=" w-full text-gray-400 p-2  flex justify-between items-center">
                    <div className=''>
                      <h3 className='text-lg'><strong>Orden:</strong> {orden.orden} </h3>
                      <h3 className='text-lg'><strong>Fecha:</strong> {orden.lastUpdate}</h3>
                    </div>

                    <h3 className='flex flex-col text-center text-lg font-bold m-0'>
                      <span className='uppercase' style={{color: orden?.estado == 'rechazado' ? '#f009' : 'black'}} >
                        { orden.estado || 'pendiente'}
                      </span>
                      <span className='text-green-500 '>
                        X{orden?.carrito.length}
                      </span> 
                    </h3> 

                    <button onClick={() => handleOpenEye(orden.orden)}>
                      <FontAwesomeIcon
                        icon={eyeActive === orden.orden ? faEyeSlash : faEye}
                        size="lg"
                        color="#26C6DA"
                      />
                    </button>

                  </div>


                  <div className='m-0'>
                    {eyeActive === orden.orden &&

                      <div className=" text-gray-400 flex flex-col justify-center mb-3">
                        <h3 className='font-bold text-lg ml-4'>Cartones :</h3>
                        {orden?.carrito.map((item) =>  
                        { 
                          
                         return  (
                         <div 
                            key={item?.numero} 
                            className='flex flex-row justify-between px-2 mx-2 my-1  border-2  rounded items-center'
                            >
                            <h4 className='w-3/12 text-sm m-0'>
                              {item.serial.slice(13, 17)}
                            </h4>
                            <h4 className={[item?.estado === "ganador" ? "text-green-400 w-8/12 pr-2 m-0 " : "text-orange-400  ", " uppercase  flex justify-end w-8/12 m-0 text-sm"]}>
                              {orden?.estado == 'rechazado' ? 'CANCELADO' : orden?.estado == 'pagado' ? 'participando' : "pendiente"  }

                            </h4>
                            <div className='w-1/12 flex justify-center items-center'>
                            {
                              orden?.estado != 'rechazado' && 
                              <button
                                className='m-auto'
                                onClick={() => handleOpenEyeCarton(item.serial)}>
                                <FontAwesomeIcon
                                  icon={faEye}
                                  size="sm"
                                  color='#26C6DA'
                                />
                              </button>
                            }
                            </div>
                          </div>)

                        }
                        )}
                      </div>
                    }
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Dashboard>
      <div className='h-full w-full mt-10 '>
        <h2 className='pl-4 max-sm:text-2xl text-white max-lg:text-3xl font-bold text-left'>Historial de Cartones</h2>
        <div>
          <div className='w-full'>
          {
              historyOrden.length  > 0 ? 
              renderHistory()
              : 
              <span className='text-white text-center w-full flex justify-center mt-10'>
                Aun no ha comprado cartones desde esta app.
              </span>
            }
             
          </div>
        </div>
      </div>

      {modalCarton &&
        <div className=" backdrop-blur-sm z-10  fixed top-0 left-0 right-0 h-screen transition-transform duration-100 flex justify-center items-center ">
          {isLoading ? <CartonLoader /> : (<div className='flex w-[95%] md:w-[75%] h-[75%] flex-col'>
            <div className='flex flex-col w-[98%] py-2 items-end'>
              <button
                onClick={handlerModalCarton}>
                <span><FontAwesomeIcon icon={faX} color='#fff' /></span>
              </button>
            </div>

            <div className='w-full h-full flex justify-center items-center'>
              <CartonInvidual
                Bolas={[]}
                Sorteo={sorteoActual}
                serialToAdd={serialToAdd}
                CartonesSaved={cartones}
                Cartones={cartones}
                Order={Orden}   
                Ribbon={{ }}
              /> 
            </div> 
          </div>)} 
        </div> 
      }
    </Dashboard>
  )
}

export default  UserHistory