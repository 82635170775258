/* eslint-disable */
import React, { useEffect, useState } from "react";
import api from "../../services/api";
import '../../App.css';
import './Carton.css';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrash,faStar} from "@fortawesome/free-solid-svg-icons";

function Carton(props) {
    const {
        Bolas,
        CartonesSaved,
        SaveCarton,
        Cartones,
        Sorteo,
        Order,
        FooterName,
        HideName,
        noDelete,
        marcaAgua,
        listMode,
        Ribbon,
        hideLogo,
        send = () => {},
        renderCart = []

    } = props

   
    
    const LoadCartones = ()=>{     
        const SortCartones =  Cartones.sort((a,b)=>{
            const ItemA = a.serial.replaceAll(Sorteo,'')
            const ItemB = b.serial.replaceAll(Sorteo,'')
            if(Order){
                if(Order == 'asc'){
                    return parseInt(ItemA)-parseInt(ItemB)
                } 
                return parseInt(ItemB)-parseInt(ItemA)
            }
            return parseInt(ItemB)-parseInt(ItemA)
        })
        return SortCartones?.map((e)=>{ 
            return CartonesSaved.map((numero)=>{
                const serial = e?.serial.replace(Sorteo,"")
                if(parseInt(serial) == parseInt(numero)){
                    return (
                    <CartonesAll 
                    
                        key={'carton-serial-'+serial}
                        datos={
                            {
                                serial:e?.serial.replace(Sorteo,""),
                                dueno:e?.nombre_cliente,
                                numeros:e?.datos
                            }
                        }
                    />)
                }
            })
        })
     
    }
    
    const DeleteCarton = (nro) =>{
        SaveCarton(CartonesSaved.filter((a)=> parseInt(a) != parseInt(nro)))
    } 
    const isWinner = (statusBolas) =>{
        let winner = true
        let Lleno = statusBolas.filter((a)=>a==false)
        if(Lleno.length > 0) winner = false
        return winner
    }  
   
    const CartonNew = ({ showPaid, footerName, datos, hideLogo }) => {
        
        let Formateado = datos.numeros.replaceAll("[","")
        Formateado = Formateado.replaceAll("]","")
        let statusBolas = [false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false,false];
        let numeros = Formateado.split(",")
        const pagado = 1 
        const status = "activo"
        const nro = datos.serial
        const propietario = datos.dueno
        const altura = hideLogo ? "195px" : "230px" 
        const last_ball = Bolas[Bolas?.length - 1]
        if(Bolas && Bolas?.length > 0){
            Bolas.map((bolita)=>{
                numeros.map((numero,index)=>{
                    if(parseInt(bolita) == parseInt(numero)){
                        statusBolas[index] = true
                    }
                    if(index == 12)statusBolas[index] = true
                })
            })
        }

        const isSelected = renderCart.filter((item)=> item.serial == nro).length > 0
        const winner = isWinner(statusBolas); 
        const isWaterMark = marcaAgua ? "carton_container marcaAgua" : "carton_container" 
        const claseContainerCss = winner ? "carton_container_winner" : isWaterMark
        return (<>
            <div className={ [listMode ? "carton_individual  list-mode" : "carton_individual  icon-mode" , isSelected && ' border-green-500 border-solid border-2 before:bg-[#00bb0055] before:w-[130px] before:h-[195px] before:absolute before:content-["SELECCIONADO"]  before:text-white before:font-bold before:text-center ']} 
            onClick={()=> send(datos)}>
                <div className={claseContainerCss} style={{height: altura}}>
                    {
                        !hideLogo && 
                         
                        <img src={"./logo_bingove_01.webp"} height={30} />
                         
                    }
                    
                    <div className="carton_head">
                        <span>CARTON {nro}</span>
                        {
                            !noDelete 
                            && 
                            <FontAwesomeIcon 
                                icon={faTrash} 
                                onClick={()=>DeleteCarton(datos.serial)}
                            />
                        }
                    </div>
                    <div  className="carton_sorteo" >
                        <span className="carton_sorteo_span">
                            {footerName ? Sorteo : propietario}
                        </span>
                    </div>
                    {
                        !listMode &&
                        <div className="carton_content_grid ">
                        {
                            numeros.map((numero,index)=>{
                                
                                if(index == 12){
                                    return (<span className="carton_number_free" key={'carton-tabla-' + index}>
                                        LIBRE
                                    </span>)
                                }
                                if(winner){
                                    return (<span className="carton_number_winner" key={'carton-tabla-' + index}>
                                        {numero}
                                    </span>)
                                }
                                if(parseInt(numero) == parseInt(last_ball)){
                                    return (<span className="carton_number_active last_ball" key={'carton-tabla-' + index}>
                                        {numero}
                                    </span>)
                                }
                                if(statusBolas[index] == true){
                                    return (<span className="carton_number_active" key={'carton-tabla-' + index}>
                                        {numero}
                                    </span>)
                                } else {
                                    return (<span className="carton_number_normal" key={'carton-tabla-' + index}>
                                        {numero}
                                    </span>)
                                }

                            })
                        }
                        </div>
                    }
                    <div>
                    
                    </div>
                </div>
                {
                    footerName &&  !HideName &&
                    <div className="carton_footer" >
                        <span>{propietario}</span>
                    </div>
                }
            </div>
        </>)
    }
    const CartonesAll = ({datos}) =>{

        return <CartonNew datos={datos} footerName={FooterName} hideLogo={hideLogo}  send={send}/>
        

    }




    return (<>
    {LoadCartones()}
    </>)
  }
  
  export default Carton;