/* eslint-disable */
import React, { useContext, useEffect, useState } from "react"; 
import '../../App.css';
import './Carton.css'; 
import AuthContext from "../../context/AuthContext";

function SimpleCard({ Cartones, Sorteo, renderCart, onClick, onSelectedCard }) { 
    const context = useContext(AuthContext)
    const [data, setData] = useState({})
   
    
    

    const handleOnClick = ( datos )=>{
        if(onClick){
            onClick( datos )
        }
    }
    const handleShowOneCard = (serial)=>{
        if(onSelectedCard){
            onSelectedCard(serial)
        }
    }
    
    useEffect(() => {
        setData(context.user)
        //console.log(context.user)
    }, [])
    
    
    return ( Cartones
        .map((carton, index)=>{

            const { numero, serial, id_cliente } = carton  
            
            const isSelected = id_cliente == data.clientId
            const numeroCarton = serial.slice(13, 17)
           
            
            return ( 
            <div key={'carton-simple-' + index }> 
                <div onClick={()=> handleOnClick(carton)} key={'carton-serial-' + numeroCarton} className={ ["carton_individual  list-mode", isSelected && ' selectedSimpleCard ']} 
                    >
                        <div className={'carton_container'} style={{height: 70}}> 
                            <div   className="carton_head text-white">
                                <span>CARTON {numeroCarton}</span> 
                            </div>
                            <div className="carton_sorteo" >
                                <span className="carton_sorteo_span">
                                    {Sorteo}
                                </span>
                            </div> 
                        </div> 
                        
                </div> 
                <div  className="bg-purple-500 text-center text-white w-full cursor-pointer" >
                        <button onClick={(e)=>{ 
                            handleShowOneCard(serial)
                        }}>
                            Mostrar
                        </button>
                    </div> 
            </div> 
            ) 
        }
    ) )
  }
  
  export default SimpleCard;