/* eslint-disable */
import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import './App.css'
import { Home, Login, Register, Sorteo, Promotores, Cartones, Faqs, Especial, ResetPassword, Dashboard } from './pages'


import ReactGA from 'react-ga'
import { CardList, UseReferidos, UserHistory, UserProfile, ViewSort } from './components'
import BolitasSet from './components/SetSorteo/BolitasSet'
ReactGA.initialize('G-75FQ87LFHC')
const App = () => {
   
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />}/>
        <Route path="faqs" element={<Faqs />} />
        <Route path="BolitasSet" element={<BolitasSet />} />

        <Route path="history" element={<UserHistory />} />
        <Route path="cardlist" element={<CardList />} />
        <Route path="sorteo" element={<ViewSort  />} />
        <Route path="referals" element={<UseReferidos   />} />
        <Route path="profile" element={<UserProfile  />} />
 
        <Route path="recuperacion" element={<ResetPassword />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="cartones" element={<Cartones />} /> 
        <Route path="promotores" element={<Promotores />} />
        <Route path="comun" element={<Cartones />} />
        <Route path="preguntas" element={<Faqs />} />
        <Route path="especial" element={<Especial />} /> 
        <Route path="*" element={<Home />} />
      </Routes>
  </BrowserRouter>
  )
}
export default App
/* <Route path="especial" element={<Especial />} /> */
