/* eslint-disable */
import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCancel, faCheck, faClock, faCopy, faDeafness, faUserClock, faX } from '@fortawesome/free-solid-svg-icons'
import AuthContext from '../../context/AuthContext';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { metodoPago } from '../../helpers/Constants';
import axios from 'axios';
import { Dashboard } from '..';
import { apiAny } from '../../services/api';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {toast, Toaster} from 'react-hot-toast';
import { isMobile } from 'react-device-detect';
import { Alert } from 'react-bootstrap';
import { getUniqueConfig } from '../../helpers/helpers';
 
const RefeData = [
	{
		"id": "1",
		"nombre": "Carlos Morales",
		"monto": "5",
		"puntos": "5",
		"fecha": "01/01/2024",
		"sorteo": "s0024"
	},
	{
		"id": "2",
		"nombre": "Carlos Morales",
		"monto": "5",
		"puntos": "5",
		"fecha": "01/02/2024",
		"sorteo": "s0025"
	},
	{
		"id": "3",
		"nombre": "Francimar Maurera",
		"monto": "5",
		"puntos": "5",
		"fecha": "01/05/2024",
		"sorteo": "s0024"
	},
];

const historialRetiroTemp = [
	{
		"id": "1",
		"fecha": "10/01/2025",
		"tipo": "Pago Movil",
		"puntos": 20,
		"monto": '64.56bs',
		"status": "procesado"
	},
	{
		"id": "2",
		"fecha": "10/01/2025",
		"tipo": "Pago Movil",
		"puntos": 20,
		"monto": '64.56bs',
		"status": "rechazado"
	},
	{
		"id": "3",
		"fecha": "10/01/2025",
		"tipo": "Pago Movil",
		"puntos": 20,
		"monto": '64.56bs',
		"status": "pendiente"
	},

]
 

function UseReferidos() {

	const context = useContext(AuthContext)
	
	const [modalRetiro, setModalRetiro] = useState(false)
	const [dollarPrice, setDollarPrice] = useState(0)
	const [putosRetirar, setPuntosRetirar] = useState('')
	const [metodoRetiro, setMetodoRetiro] = useState('')

	const [referidosData, setReferidosData] = useState([])
	const [referidos, setReferidos] = useState([])
	const [historialRetiro, setHistorialRetiro] = useState([])
	const [nivel, setNivel ] = useState( 0.05 )
	const [putosSorteo, setPuntosSorteo] = useState([])

	const price_api = axios.create({
		baseURL: process.env.REACT_APP_API_DOLLAR
	})

	const totalPontos = RefeData.reduce((total, item) => total + Number(item.puntos), 0)
	const convercion = (totalPontos * 0.1).toFixed(1) 

	const [precioCarton, setPrecioCarton] = useState(1)

	const fetchRefeals = async ( ) => { 
		 await apiAny({
			method: "get",
			url: "/v2/users/getRefeals/" + context.user.clientId 
		})
		.then(function (response) {
			//handle success
			if(response.data.status == 1 ){
				const content = response.data.content
				const temporalRefers = []
				if( content .length > 0){ 
					content.forEach(item => {
						if(temporalRefers.filter((a)=> a.id == item.id ).length == 0){ 
							temporalRefers.push(item) 
						} 
					})	
					setReferidos(temporalRefers)
					setReferidosData(
						content
						.sort(( a, b )=>{
							if(a.sorteo != null && b.sorteo != null){ 
								return b.sorteo.localeCompare(a.sorteo)
							}
							return false
						})
						.filter((item)=>item.estado === 'pagado')
					)
					const sorteoPuntos = {}
				 
					content.map((item)=>{
						if(item.estado === 'pagado'){
							if(item?.sorteo != null){ 
								const sorteo = item.sorteo
								const monto = item.monto 
								const encontrados = sorteoPuntos?.[item.sorteo] 
								if(encontrados != null){
									sorteoPuntos[sorteo].monto += parseInt(monto) 
								} else {
									sorteoPuntos[sorteo]  = { sorteo, monto: parseInt(monto) } 
								}	
							}
						}
					}) 
					setPuntosSorteo(Object.entries(sorteoPuntos))
					console.log('sorteoPuntos', Object.entries(sorteoPuntos))
				}
			}
			console.log(response);
		})
		.catch(function (response) {
			//handle error
			console.log(response);
		}); 
		
	}
 
 
	const getPriceDollar = async () => {
		await price_api.get('dollar?page=bcv')
		.then((response) => {
			const monitores = response.data.monitors
			setDollarPrice(monitores.usd.price)
		})
		.catch((err) => {
			console.error("ops! ocorreu um erro" + err);
		});
	}

	useEffect(() => {
		if(context.user.clientId > -1 ){ 
			fetchRefeals()
		}
		getPriceDollar()
		setPrecioCarton( parseFloat( getUniqueConfig('cartones_precio', context.configs) ) || 60 )
	}, [context])

	const validationSchema = Yup.object({
		puntos: Yup.string().required("El monto es obligatorio")
			.matches(/^\d+$/, "Debe contener solo números")
			.min(2, "Minimo 11 Numeros"),
		metodoPago: Yup.string().required("El metodo de pago es requerido")
	});

	const formik = useFormik({
		initialValues: {
			puntos: "",
			metodoPago: ""
		},
		validationSchema,
		onSubmit: (values) => {
			// api
		},
	});

	const handleCancel = () => {
		formik.values.puntos = ''
		formik.values.metodoPago = ''
		setModalRetiro(false)
	}

	 
	return (
		<Dashboard> 
			<div className='mt-10 flex flex-col container w-full items-stretch bg-[#1a1a1a] scroll-y'>
			<Toaster
												position="top-right"
												reverseOrder={false}
												toastOptions={{
													style: {
														marginTop: 50
													},
												}}
											/>
				<div className='w-full flex flex-row justify-between items-center'>
					<h2 className='text-white text-3xl mt-2 mb-6'>Referidos</h2>
					<div className='w-40 h-20 bg-gray-800 rounded-lg mt-4 text-white flex flex-col justify-between items-center'>
						<strong className='text-white'>Nivel 1 (5%) </strong>
						<strong className='mt-1 mb-2'> {referidosData.reduce(( a, b ) => a + parseInt(b.monto)  , 0 )} pts (<strong className='text-green-400'>{ ( referidosData.reduce(( a, b ) => a + parseInt(b.monto)  , 0 ) * nivel * precioCarton).toFixed(0) } Bs </strong> ) </strong>
						<button
							onClick={() => setModalRetiro(true)}
							disabled  
							className='w-full rounded-lg bg-purple-700 text-white fon-bold'>
								Balance
						</button>
					</div>
				</div>

				<hr  className='border-white my-4' />
				<span className='text-white text-center'>Link de refedido</span>
				< div className='mb-4 text-white flex justify-center w-full gap-2'>
					<span  className='text-purple-300' 
						href={"https://bingove.com/register?cref=" + context.user.clientId}>
						<span>https://bingove.com/...?cref={context.user.clientId}</span>
					</span>
					<CopyToClipboard 
						text={"https://bingove.com/register?cref=" + context.user.clientId} 
						//onCopy={() =>  toast.error(`link Copiado ✅`) }
						className="flex gap-2  items-center px-2 rounded-lg bg-purple-700 text-white fon-bold cursor-pointer">
							<button onClick={()=>{console.log('boton')
								toast.success(`link Copiado ✅`, {duration: 5000})
							}
							}> 
								<FontAwesomeIcon icon={faCopy} />
								<span className="font-[300]">Copiar</span> 
							</button>
					</CopyToClipboard>
					 
				</div>
				{/* Tabla de Total de Referidos */}
				< div className=' '> 
					<table className='border text-white w-1/2 lg:w-1/4 mx-auto'>
						<thead className='bg-purple-700'>
							<tr>
								<th className='p-2 text-center'>Total de referidos</th>
							</tr>
						</thead>
						<tbody>  
							{referidosData.length == 0 ?  (
								<tr key={history.orden} className="border text-center">
									<td className="p-3 text-white text-center">
										Aun no Tienes Ingresos
									</td> 
								</tr>)
								:
								(<tr>
									<td className='p-2 text-center'>{referidos.length}</td>
								</tr>)
							}
						</tbody>
					</table> 
				</div>
				<hr  className='border-white my-4' />
				{/* Tabla Detallada */}
				<div className="overflow-x-auto flex justify-center items-center">
					{referidosData.length > 0 && (
						<table className="w-full border max-h-[75%] srroll-y-auto  lg:w-[40%]">
							<thead className="bg-purple-700">
								<tr>
									<th className="p-3 text-center text-white border-b">Sorteo</th>
									<th className="p-3 text-center text-white border-b">Cliente</th>
									<th className="p-3 text-center text-white border-b">Monto</th>
									<th className="p-3 text-center text-white border-b">Puntos</th>
									<th className="p-3 text-white border-b text-center">Fecha</th>
								</tr>
							</thead>
							<tbody className=''>
								{referidosData.map((history) => (
									<tr key={history.orden} className="border text-center">
										<td className="p-3 text-white text-center">{history?.sorteo?.substr(0,5)}</td>
										<td className="p-3 text-white text-center">{history.clientId}</td>
										<td className="p-3 text-white text-center">{Number(history.monto).toFixed(2)}$</td>
										<td className="p-3 text-white text-center">{(Number(history.monto)).toFixed(0)}</td>
										<td className="p-3 text-white text-center">{history.fecha}</td>
									</tr>
								))}
								{referidosData.length == 0 && 
								<tr key={history.orden} className="border text-center">
										<td className="p-3 text-white text-center">
											Aun no tienes ingresos
										</td> 
									</tr>
								}
							</tbody>
						</table>
					) }
				</div> 
				<hr  className='border-white my-4' />
				<div className=' '>
					<h2 className='text-white'>Historial de Retiros</h2>
					<div className='mt-3 mb-10 flex flex-col justify-center items-center'>
						{	historialRetiro.sort((a,b)=>b.id - a.id).map((item) => (
							
							<div className=' w-full lg:w-[40%] bg-white rounded-lg gap-2 flex flex-row justify-between pt-2 px-4 my-2'> 
								<span className='text-base text-gray-500 '>{item.id}</span>
								<span className='text-base text-gray-500 '>{item.fecha}</span>
								<span className='text-base text-gray-500 ' >{item.tipo}</span>
								<span className='text-base text-gray-500 ' >({item.puntos}pts)</span>
								<span className='text-base text-gray-500 ' >{item.monto}</span>
								<span className={`text-base uppercase font-bold ${
									item.status === 'procesado' ? 'text-green-500'
									: item.status === 'pendiente' ? 'text-black-500'
										: 'text-red-500'}`}>
											<FontAwesomeIcon icon={
												item.status === 'procesado' ?  faCheck: 
												item.status === 'pendiente' ? faUserClock:  
												faCancel
											} /> 
									</span>
							</div>
							))
						}
						{	historialRetiro.length == 0 && 
							 
							<span className="p-3 text-white text-center w-[80%]">

								Sigue acumulando ingresos, proximamente podras rertirar tu balance acumulando y
								participar en el sorteo comprando con el saldo del balance.
								aumenta de nivel para aumentar las ganancias por el total de ventas en cada sorteo.   

							</span>  
						}

					</div>
				</div>
					
 
				{modalRetiro && (
					<div className=" backdrop-blur-sm z-10  fixed top-0 left-0 right-0 h-full transition-transform duration-100 flex justify-center items-center ">
						<div className=" flex w-[95%] md:w-[65%] lg:w-[40%] h-auto bg-white rounded">
							<div className='flex flex-col w-full'>
								<div className='w-full h-[50px] flex justify-between  pt-3 px-3'>
									<h3>Retirar Puntos</h3>
									<button
										onClick={() => setModalRetiro(false)}>
										<FontAwesomeIcon icon={faX} color='#f00' />
									</button>
								</div>

								<form
									className='flex flex-col h-full m-4 '
									onSubmit={formik.handleSubmit}
								>

									<label className='pb-1 mt-2 font-bold' htmlFor="">Puntos</label>
									<input
										validate={(value) => setPuntosRetirar(value)}
										id='puntos'
										name='puntos'
										placeholder='Ingrese los puntos que quiere retirar'
										type="text"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.puntos}
										className={`bg-gray-50 border ${formik.touched.puntos &&
											formik.errors.puntos
											? "border-red-500"
											: "border-gray-300"
											} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
									/>
									{formik.touched.puntos &&
										formik.errors.puntos && (
											<p className="text-red-500 text-sm mt-1">
												{formik.errors.puntos}
											</p>
										)}

									<label className='pb-1 mt-2 font-bold' htmlFor="">Forma de Pago</label>
									<select
										validate={(value) => setMetodoRetiro(value)}
										id='metodoPago'
										name='metodoPago'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.metodoPago}
										className={`bg-gray-50 border ${formik.touched.metodoPago && formik.errors.metodoPago
											? "border-red-500"
											: "border-gray-300"
											} text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`}
									>
										<option value="" label="-- Seleccione metodo de retiro --"></option>
										<option value="1">Pago Movil</option>
										<option value="2">Trasferencia</option>
									</select>
									{formik.touched.metodoPago && formik.errors.metodoPago && (
										<p className="text-red-500 text-sm mt-1">
											{(formik.errors.metodoPago)}
										</p>
									)}

									{formik.values.puntos > 0 &&
										<div className='w-full flex justify-center mt-4 items-center'>
											<div className='bg-gray-200 text-center  w-1/2 mt-2 h-auto rounded-lg px-4 py-2'>
												<span className='text-md font-bold  w-full'>
													Cuanto Recibes
												</span>
												<div className='flex flex-row items-center justify-center mt-1 py-2'>
													<strong className='text-red-500'>{formik.values.puntos}pts ({(Number(formik.values.puntos) * 0.05).toFixed(2)}$)  </strong>
													<strong className='mx-1'> = </strong>
													<strong className='text-green-500'>{((Number(formik.values.puntos) * 0.05) * dollarPrice).toFixed(2)} Bs </strong>
												</div>
											</div>
										</div>
									}

									<div className='w-full h-[10%] flex justify-center items-center gap-2  mt-10 mb-2 pb-2 px-1 lg:px-4 '>
										<button
											className='w-1/2 bg-[#bbb] uppercase font-bold rounded-md py-2 px-2'
											onClick={handleCancel}
										>
											Cancelar
										</button>
										<button
											onClick={()=>alert('Funcion en desarrollo, pronto podras retirar tus ganancias de bingove')}
											className='w-1/2 bg-purple-700 disabled:bg-gray-200 uppercase font-bold text-white rounded-md py-2 px-2'
										>
											Retirar
										</button>
									</div>

								</form>
								
							</div>
							
						</div>
					</div>
				)}		
			</div >
		</Dashboard>
	);
}

export default UseReferidos ;