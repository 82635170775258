/* eslint-disable */
import './Cartones.css';
import React, { useCallback, useEffect, useRef, useState, version } from "react";
import api from "../../services/api";
import {Header,Footer,Youtube,Carton,Tablero,Chat,Loader} from '../../components';
import { isMobile } from "react-device-detect";
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

function Cartones() {
    const location = useLocation();
    const [Cartones,setCartones] = useState([]);
    const [serialToAdd,setSerialToAdd] = useState();      
    const [premioDiagonal,setPremioDiagonal] = useState(0);
    const [premioEsquinas,setPremioEsquinas] = useState(0);
    const [premioLleno,setPremioLleno] = useState(0);
    const [AppVersion,setAppVersion] = useState(0); 
    const [DiaSorteo,setDiaSorteo] = useState(0);
    const [Promotores,setPromotores] = useState(0); 
    const [CartonesLocal,setCartonesLocal] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [Orden,setOrden] = useState("asc")
    const [listMode,setListMode] = useState(false);
    const Consola = (value) =>  console.log(value)

    //todo para  configuraciones
    const [configs, setConfigs] = useState([]);
    const [link, setLink] = useState();
    const [vendidos,setVendidos] = useState();
    const [TotalRepartir, setTotalRepartir] = useState(0)
    const [sorteoActual, setSorteoActual] = useState();
    const [historial, setHistorial] = useState(false);
    const [PremiosLittle, setPremiosLittle] = useState(0)
    const [PremiosBig, setPremiosBig] = useState(0)
    const [videoHome1, setVideoHome1] = useState({})
    const [videoHome2, setVideoHome2] = useState({})
    const [videoUltimo, setVideoUltimo] = useState({})
    const [publicidad, setPublicidad] = useState(0)
    const [fecha, setFecha] = useState();
    const [dia, setDia] = useState();
    const [hora, setHora] = useState();
    const [rondas, setRondas] = useState(1);
    const [precioCarton, setPrecioCarton] = useState(1);
    const [ventasStatus, setVentasStatus] = useState(false);
    const [promotorStatus, setPromotorStatus] = useState(false);
    const [promotorEmpleo, setPromotorEmpleo] = useState(false); 
    const [adminStatus, setAdminStatus] = useState(false);
    const [tipoSorteo, setTipoSorteo] = useState("normal");
    const [linkCartonesNormal, setLinkCartonesNormal] = useState("/cartones");
    const [linkCartonesEspecial, setLinkCartonesEspecial] = useState("/especial");
    //####################### 
    
    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location]);

    const GetAllConfigs = async () =>{
        await  api.get('/read/configs')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempConfigs = response?.data?.content
                 setConfigs(TempConfigs);
                 TempConfigs?.map((item)=>{
                     const {id,tipo,valor,extra} = item
                     switch(tipo){ 
                         case 'sorteo_actual':  
                             setSorteoActual(valor) 
                             break;
                         case 'sorteo_youtube':  
                             setLink(valor)
                             break; 
                         case 'sorteo_fecha':  
                             setFecha(valor)
                             break;
                         case 'sorteo_hora':  
                             setHora(valor)
                             break;
                         case 'sorteo_dia':  
                             setDia(valor)
                             break;
                         case 'ventas_status':  
                             setVentasStatus(valor=='1')
                             break;
                         case 'promotores_status':  
                             setPromotorStatus(valor=='1')
                             break;
                         case 'administradores_status':  
                             setAdminStatus(valor=='1')
                             break;
                         case 'sorteo_rondas':  
                             setRondas(valor)
                             break;
                         case 'premio_base_small':  
                             setPremiosLittle(parseFloat(valor))
                             break;
                         case 'premio_base_big':  
                             setPremiosBig(parseFloat(valor))
                             break;
                         case 'premio_base_total':  
                             setTotalRepartir(parseFloat(valor))
                             break; 
                         case 'cartones_precio':  
                             setPrecioCarton(parseFloat(valor));
                             break;
                         case 'promotores_empleo':  
                             setPromotorEmpleo(valor=='1')
                             break; 
                         case 'video_home_1':  
                             setVideoHome1(item)
                             break; 
                         case 'video_home_2':  
                             setVideoHome2(item)
                             break; 
                         case 'video_ultimo_sorteo':  
                             setVideoUltimo(item)
                             break;
                         case 'cartones_vendidos':  
                             setVendidos(parseInt(valor))
                             break;
                         case 'precio_publicidad':  
                             setPublicidad(parseInt(valor))
                             break;
                         case 'home_historial':  
                             setHistorial(valor=='1')
                             break;
                             //cartones_vendidos
                         default :  break;
                     }  
 
 
                 })
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       }); 
     }
 
    const GetCartones = async()=>{
        await  api.get('/read/sorteo_cartones_free')
         .then((response) =>{ 
             if(response?.data?.content.length > 0){
                 const TempCartones= response?.data?.content
                 const TempLista = []
                 const TempCartonesSorteo =  TempCartones
                .map((item)=>{
                    TempLista.push(item.serial.replaceAll(sorteoActual,''))
                })
                 setCartones(TempCartones)
                 setCartonesLocal(TempLista)  
             } 
          })
       .catch((err) => {
           console.error("ops! ocorreu um erro" + err);
       }); 
    } 

    useEffect( () => {
        GetAllConfigs()
    }, []);

    const CargarLoader = ()=>{
        const interval = setInterval(()=>{
            setIsLoading(false)
        }, 3000);
        return () => clearInterval(interval);
    }

    useEffect( () => {
        GetCartones()
        CargarLoader()
    }, [sorteoActual]);

    const handlerOrden = ()=>{

        //setIsLoading(true)
        
        if(Orden=="asc"){
            setOrden("desc")
        } else {
            setOrden("asc")
        }

        CargarLoader()
    }
    const handlerLista = ()=>{

        //setIsLoading(true)
        setListMode(!listMode)
        CargarLoader()
    }

    

    const HeaderPanel = () =>{

        return (<>
        
        <div className="flex p-2 font-bold items-center justify-between gap-x-[10px] mt-[70px] lg:mt-0">
            {   !isMobile &&
                <span className="panel-cartones-version">
                    <b>
                        Version : {AppVersion}
                    </b>
                </span>
            }
            <span className="panel-cartones-sorteo">
                <b>
                    Sorteo Actual : {sorteoActual}
                </b>
            </span> 
            <div className="flex p-2 gap-x-[10px] ">
                <span className="flex cursor-pointer p-2 pl-4 pr-4 bg-[#2c98d3] rounded-[5px]" onClick={handlerLista}>
                    <b >{listMode ?"Completo":"Lista"}</b>  
                </span>
                <span className="flex cursor-pointer p-2 bg-[#2c98d3] rounded-[5px]" onClick={handlerOrden}>
                    <b >{Orden=="asc"?"Ascendente":"Descendente"}</b>  
                </span>
            </div>
        </div>
        
        </>)
    }
    return (
        <div className="pages-cartones" style={{color:"white"}}>
            {
                isLoading
                && 
                <Loader />
            }
            <Header noLogin={true} />
            <HeaderPanel />
            <div className="page-content">
                <div className="flex flex-wrap gap-4 row-principal cartones-row justify-center">
                    <Carton 
                        Bolas={[]}
                        CartonesSaved={CartonesLocal}
                        SaveCarton={()=>{}}
                        Sorteo={sorteoActual}
                        serialToAdd={serialToAdd} 
                        Cartones={Cartones}
                        Order={Orden}
                        FooterName
                        HideName
                        noDelete
                        marcaAgua
                        listMode={listMode} 
                    />
                </div>
            </div>
            <Footer noLogin={true} />
        </div>
      
    );
  }
  export default Cartones;